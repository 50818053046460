import { DialogModalDS, RowClickable, Spacer } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InOppositionOptions } from "./StepsToInOppositionModal";

export const BadgeInOppositionModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  nextStep: (step: InOppositionOptions) => void;
}) => {
  const { t } = useTranslation();

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={() => {
        props.onCloseModal();
      }}
      title={t("badges.inOppositionModal.title")}
      width={35}
    >
      <StyledModalContainer>
        <RowClickable
          text={t("badges.inOppositionModal.inOpposition.title")}
          subText={t("badges.inOppositionModal.inOpposition.text")}
          onClick={() => props.nextStep("IN_OPPOSITION")}
        />
        <Spacer y={1} />
        <RowClickable
          text={t("badges.inOppositionModal.inOppositionAndOrder.title")}
          subText={t("badges.inOppositionModal.inOppositionAndOrder.text")}
          onClick={() => props.nextStep("IN_OPPOSITION_AND_ORDER")}
        />
        <Spacer y={1} />
        <RowClickable
          text={t("badges.inOppositionModal.return.title")}
          subText={t("badges.inOppositionModal.return.text")}
          onClick={() => props.nextStep("RETURN")}
        />
        <Spacer y={1} />
        <RowClickable
          text={t("badges.inOppositionModal.returnAndReplace.title")}
          subText={t("badges.inOppositionModal.returnAndReplace.text")}
          onClick={() => props.nextStep("RETURN_AND_REPLACE")}
        />
      </StyledModalContainer>
    </DialogModalDS>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
