import {
  Spacer,
  ToastContainer,
  PageDS,
  TabMenu,
  AddOutlined,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AccountTab } from "./accountTab";
import { useNavigate, useParams } from "react-router-dom";
import { UserManagementTab } from "./userManagementTab";
import { AddUserModal } from "./addUserModal";

export const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "account",
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const tabs = [
    {
      name: "account",
      title: t("users.tab.account"),
    },
    {
      name: "management",
      title: t("users.tab.management"),
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/users/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  return (
    <PageDS
      title={t("users.title")}
      toaster={<ToastContainer />}
      cta={{
        leftIcon: <AddOutlined />,
        title: t(`users.tab.management.button.add`),
        action: () => setIsModalVisible(true),
      }}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Spacer y={0.5} />
        </>
      }
    >
      <AccountTab />
      <UserManagementTab />
      <AddUserModal
        visible={isModalVisible}
        onCloseModal={() => {
          setIsModalVisible(false);
        }}
      />
    </PageDS>
  );
};
