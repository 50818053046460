import { createAsyncThunk } from "@reduxjs/toolkit";

export const documentDownloadXlsxAsync = createAsyncThunk(
  "documentDownload/call",
  (doc: DownloadDocument) => {
    const a = document.createElement("a");
    a.download = doc.fileName || "yourDocument.xlsx";
    const url = window.URL.createObjectURL(new Blob([doc.buffer]));
    a.href = url;
    a.click();
  },
);

type DownloadDocument = {
  buffer: Buffer;
  fileName: string;
};
