import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ButtonDS,
  CachedOutlined,
  DownloadOutlined,
  DragAndDrop,
  FileInputButton,
  FilePresentOutlined,
  StepFrame,
  StepStates,
  TextCapitalized,
  cellsContentErrorsForMultipleVehicles,
  transformRegistrationNumber,
  triggerToast,
} from "@qivia/ui";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { useEffect, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  CellsErrorsType,
  VehicleBulkCreationPayload,
  VehicleDataType,
  VehicleType,
} from "../vehiclesAPI";
import * as XLSX from "xlsx";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { importMassiveVehiclesAsync } from "../vehiclesSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import vehiclesXlsxModel from "../../../../../vehicles_import_files.xlsx?url";
import { ImportVehicles } from "@qivia/ui/src/assets/assets";
import { selectCompany } from "../../homeSlice";

export const ImportVehiclesModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState<number>(1);

  if (!props.visible) {
    return;
  }

  return (
    <StyledFrame>
      <Spacer x={2.5} />
      <StyledColumn>
        <Spacer y={2.5} />
        <ReturnButton onCloseModal={props.onCloseModal} />
        <Spacer y={2.5} />
        <StyledContentImport>
          <StepStates
            title={t("vehicles.modal.addMultipleVehicles.stepstates.title")}
            selectedStep={selectedStep}
            text={"vehicles.modal.addMultipleVehicles.stepstates"}
            numberOfSteps={3}
          />
          <Spacer y={2} />
          <StepFrame
            index={selectedStep}
            title={t(
              `vehicles.modal.addMultipleVehicles.stepframe.title.step${selectedStep}`,
            )}
            subTitle={t(
              `vehicles.modal.addMultipleVehicles.stepframe.subTitle.step${selectedStep}`,
            )}
          >
            <>
              {selectedStep === 1 ? (
                <FirstState />
              ) : selectedStep === 2 ? (
                <SecondState />
              ) : (
                <ThirdState onCloseModal={props.onCloseModal} />
              )}
            </>
          </StepFrame>
          <Spacer y={2.5} />
          {selectedStep < 3 && (
            <ButtonDS
              text={t("vehicles.modal.addMultipleVehicles.button.next")}
              onClick={() => setSelectedStep(selectedStep + 1)}
              buttonType={"primary"}
              format={"hug"}
              rightIcon={<ArrowForwardOutlined />}
            />
          )}
        </StyledContentImport>
        <Spacer y={2.5} />
      </StyledColumn>
      <Spacer x={2.5} />
    </StyledFrame>
  );
};

const FirstState = () => {
  const { t } = useTranslation();
  const fileName = t("vehicles.modal.addMultipleVehicles.filename");
  return <DisplayFile selectedFile={fileName} type="DOWNLOAD" />;
};

const SecondState = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledExample>
        <Spacer y={1} />
        <StyledFlex>
          <Spacer x={1} />
          👋
          <Spacer x={0.4} />
          <TextCapitalized>
            {t(
              "vehicles.modal.addMultipleVehicles.stepframe.title.step2.image",
            )}
          </TextCapitalized>
          <Spacer x={1} />
        </StyledFlex>
        <Spacer y={0.75} />
        <StyledFlexCenter>
          <Spacer x={1} />
          <ImportVehicles />
          <Spacer x={1} />
        </StyledFlexCenter>
        <Spacer y={0.75} />
      </StyledExample>
      <Spacer y={1.5} />
      <StyledRules>
        <Spacer x={1} />
        <div>
          <Spacer y={1} />
          <TextCapitalized>
            {t(
              "vehicles.modal.addMultipleVehicles.stepframe.title.step2.rules",
            )}
          </TextCapitalized>
          <RulesListComplete />
        </div>
        <Spacer x={1} />
      </StyledRules>
      <Spacer y={1} />
    </>
  );
};

const ThirdState = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [jsonFile, setJsonFile] = useState<VehicleDataType[]>([]);
  const [cellsError, setCellsError] = useState<CellsErrorsType[]>([]);
  const [isValidImport, setIsValidImport] = useState<boolean>(false);
  const [hasExtensionError, setHasExtensionError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const company = useAppSelector(selectCompany);

  const MIME = {
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const transformVehicleDataToCreationAPI = useCallback(
    (vehicleData: VehicleDataType): VehicleBulkCreationPayload => {
      const registrationNumber = transformRegistrationNumber(
        vehicleData["Immatriculation"],
      );
      const vehicleType = mapVehicleType(
        vehicleData["Type de véhicule"].toUpperCase(),
      );
      const reference = vehicleData["Référence"] || null;
      const additionalCostPerKm = vehicleData["Surcoût au km"]
        ? vehicleData["Surcoût au km"] * 100
        : null;
      const contractStartDate = vehicleData["Date début de contrat"]
        ? new Date(
            Math.round(
              (vehicleData["Date début de contrat"] - 25569) * 86400 * 1000,
            ),
          ).toISOString()
        : null;
      const contractEndDate = vehicleData["Date fin de contrat"]
        ? new Date(
            Math.round(
              (vehicleData["Date fin de contrat"] - 25569) * 86400 * 1000,
            ),
          ).toISOString()
        : null;
      const contractMileage = vehicleData["Kilométrage contractuel"] ?? null;

      return {
        registrationNumber: registrationNumber?.toUpperCase() ?? null,
        vehicleType: vehicleType as VehicleType,
        reference: reference?.toString().toUpperCase() ?? null,
        additionalCostPerKm,
        contractStartDate,
        contractEndDate,
        contractMileage,
      };
    },
    [],
  );

  const mapVehicleType = (type: string) => {
    switch (type) {
      case "MULTI-CONDUCTEURS":
        return "SHARED";
      case "MONO-CONDUCTEUR":
        return "PERSONAL";
      default:
        return type;
    }
  };

  const checkFile = useCallback(
    (selectedFile: File) => {
      if (selectedFile && selectedFile.type !== MIME.xlsx) {
        setHasExtensionError(true);
      } else if (selectedFile) {
        setHasExtensionError(false);
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(selectedFile);
        fileReader.onload = (e) => {
          if (e?.target) {
            try {
              const bufferArray = e.target.result;
              const workBook = XLSX.read(bufferArray, { type: "buffer" });
              const workBookName = workBook.SheetNames[1];
              const workSheet = workBook.Sheets[workBookName];
              const data = XLSX.utils.sheet_to_json(workSheet);

              const cellsContentErrors = cellsContentErrorsForMultipleVehicles(
                workSheet,
                data.length,
              );
              setCellsError(cellsContentErrors);

              setIsValidImport(cellsContentErrors.length === 0);
              setJsonFile(isValidImport ? (data as VehicleDataType[]) : []);
            } catch (e) {
              setJsonFile([]);
              setIsValidImport(false);
              setCellsError([
                {
                  cell: "",
                  content: "",
                  errorType: "model",
                },
              ]);
            }
          }
        };
      } else {
        triggerToast(t("vehicles.mime.type.failure"), "error");
      }
    },
    [MIME.xlsx, isValidImport, t],
  );

  const onSubmitVehicles = useCallback(() => {
    if (!company) {
      return;
    }
    const vehiclesToCreate = jsonFile.map((vehicleData) =>
      transformVehicleDataToCreationAPI(vehicleData),
    );
    void dispatch(
      importMassiveVehiclesAsync({
        vehicles: vehiclesToCreate,
        companyUuid: company.uuid,
      }),
    );
    setCellsError([]);
    props.onCloseModal();
  }, [company, dispatch, jsonFile, props, transformVehicleDataToCreationAPI]);

  const clickToSubmit = useCallback(() => {
    isValidImport && onSubmitVehicles();
  }, [isValidImport, onSubmitVehicles]);

  useEffect(() => {
    if (selectedFile) checkFile(selectedFile);
  }, [checkFile, selectedFile]);

  const isFileAlreadyImport =
    (isValidImport || cellsError.length > 0) && !hasExtensionError;

  return (
    <>
      {isFileAlreadyImport ? (
        <>
          <DisplayFile
            selectedFile={selectedFile?.name}
            handleFileChange={setSelectedFile}
            type="REPLACE"
          />
          {cellsError.length > 0 && (
            <StyledFlex>
              <Spacer x={0.75} />
              <StyledColumn>
                <Spacer y={1.5} />
                <StyledCountErrors>
                  {cellsError.length}
                  {t(
                    `vehicles.modal.addMultipleVehicles.${
                      cellsError.length === 1 ? "countError" : "countErrors"
                    }`,
                  )}
                </StyledCountErrors>
                <Spacer y={0.5} />
                <StyledCells>
                  {cellsError.map((error, index) => (
                    <Cell error={error} key={"cells" + index} />
                  ))}
                  <Spacer y={0.25} />
                </StyledCells>
              </StyledColumn>
              <Spacer x={0.75} />
            </StyledFlex>
          )}
          <Spacer y={1.5} />
          {isValidImport && (
            <ButtonDS
              text={t("vehicles.modal.addMultipleVehicles.add")}
              format={"hug"}
              buttonType={"primary"}
              onClick={clickToSubmit}
              disabled={!isValidImport}
            />
          )}
        </>
      ) : (
        <StyledFlexCenter>
          <DragAndDrop
            handleFileChange={setSelectedFile}
            body={t("vehicles.modal.addMultipleVehicles.dragAndDrop.subTitle")}
            hasExtensionError={hasExtensionError}
          />
        </StyledFlexCenter>
      )}
    </>
  );
};

const ReturnButton = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <ButtonDS
        text={t("vehicles.modal.addMultipleVehicles.button.return")}
        leftIcon={<ArrowBackOutlined />}
        onClick={() => props.onCloseModal()}
        buttonType={"secondary"}
        format={"hug"}
      />
    </div>
  );
};

export type DisplayFileType = "REPLACE" | "DOWNLOAD";

const DisplayFile = (props: {
  selectedFile?: string;
  handleFileChange?: (event: File | null) => void;
  type: DisplayFileType;
}) => {
  const { t } = useTranslation();

  return (
    <StyledFlex>
      <Spacer x={0.75} />
      <StyledDisplayFile>
        <StyledLeft>
          <StyledIcon>
            <FilePresentOutlined />
          </StyledIcon>
          <Spacer x={0.5} />
          {props.selectedFile}
        </StyledLeft>
        <Spacer x={0.25} />
        {props.type === "REPLACE" ? (
          <FileInputButton
            handleFile={(file) => props.handleFileChange?.(file)}
          >
            <ButtonDS
              format="hug"
              buttonType={"secondary"}
              text={t(`vehicles.modal.addMultipleVehicles.button.replace`)}
              leftIcon={<CachedOutlined />}
            />
          </FileInputButton>
        ) : (
          <StyledDownload
            download={props.selectedFile}
            href={vehiclesXlsxModel}
            style={{ textDecoration: "none" }}
          >
            <ButtonDS
              text={t(`vehicles.modal.addMultipleVehicles.button.download`)}
              leftIcon={<DownloadOutlined />}
              format="hug"
              buttonType="secondary"
            />
          </StyledDownload>
        )}
      </StyledDisplayFile>
      <Spacer x={0.75} />
    </StyledFlex>
  );
};

const RulesListComplete = () => {
  const ruleElements = [];

  for (let i = 1; i <= 3; i++) {
    ruleElements.push(
      <StyledRule key={i}>
        <TextCapitalized>
          <Trans
            i18nKey={`vehicles.modal.addMultipleVehicles.stepframe.title.step2.rule${i}`}
          />
        </TextCapitalized>
        <Spacer y={0.5} />
      </StyledRule>,
    );
  }
  return <ul>{ruleElements}</ul>;
};

const Cell = (props: { error: CellsErrorsType }) => {
  const { t } = useTranslation();

  const nameCell =
    props.error.cell.length > 0
      ? `${t("vehicles.modal.addMultipleVehicles.cell")}
      ${props.error.cell.slice(1)}, 
      ${props.error.cell[0]}`
      : "";

  const truncatedContent =
    props.error.content && props.error.content.length > 28
      ? `${props.error.content.substring(0, 28)}...`
      : props.error.content;

  return (
    <>
      <StyledContainerCell>
        <Spacer x={0.25} />
        <StyledContent>
          <StyledPlace>
            <TextCapitalized>{nameCell}</TextCapitalized>
          </StyledPlace>
          <StyledTypeErrors>
            <StyledTypeErrorsTop>{truncatedContent}</StyledTypeErrorsTop>
            <StyledTypeErrorsBottom>
              <TextCapitalized>
                {t(
                  `vehicles.modal.addMultipleVehicles.errorType.${props.error.errorType}`,
                )}
              </TextCapitalized>
            </StyledTypeErrorsBottom>
          </StyledTypeErrors>
        </StyledContent>
        <Spacer x={0.75} />
      </StyledContainerCell>
      <Spacer y={0.25} />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledPlace = styled.div`
  ${typographies["Body/XL"]};
  display: flex;
  color: ${colors["colors/text/black"]};
`;

const StyledDownload = styled.a`
  &:focus {
    outline: none;
  }
`;

const StyledTypeErrors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTypeErrorsTop = styled.div`
  display: flex;
  ${typographies["Body/XL"]};
  color: ${colors["colors/text/black"]};
`;

const StyledTypeErrorsBottom = styled.div`
  display: flex;
  ${typographies["Body/S"]};
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledContainerCell = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  width: 100%;
  ${typographies["Body/XL"]};
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledRule = styled.li`
  ${typographies["Body/XS"]};
`;

const StyledRules = styled.div`
  background: ${colors["colors/system/informative/informative_ultraLight"]};
  border-radius: 0.5rem;
  color: ${colors["colors/text/black"]};
  display: flex;
  ${typographies["Body/M"]};
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledFlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledExample = styled.div`
  ${typographies["Body/M"]};
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/button/secondary/hover"]};
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  border-radius: 0.5rem;
  & svg {
    width: 31.5rem;
  }
  align-items: center;
`;

const StyledCells = styled.div`
  overflow-y: scroll;
  max-height: 15rem;
  width: 100%;
`;

const StyledDisplayFile = styled.div`
  width: 100%;
  display: flex;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  padding: 0.75rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  ${typographies["Body/M"]};
  color: ${colors["colors/accent/600"]};
`;

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.div`
  & svg {
    fill: ${colors["colors/accent/600"]};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.25rem;
  height: 2.25rem;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledCountErrors = styled.div`
  display: flex;
  justify-content: flex-start;
  ${typographies["Body/M"]};
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledFrame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.75rem 0 0 0.75rem;
`;

const StyledColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContentImport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;
