import { GridAggregationInitialState } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { GridEvents } from "@mui/x-data-grid/models/events";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";

export function parseOrNull(raw: string): GridInitialStatePremium | null {
  if (!raw) return null;

  if (typeof raw === "string") {
    try {
      return JSON.parse(raw) as GridInitialStatePremium;
    } catch (e) {
      console.warn(`Failed to parse: ${raw.substring(0, 50)}`);
      return null;
    }
  }

  return null;
}

export function usePersistMuiTableState(
  apiRef: MutableRefObject<GridApiPremium>,
  key: string,
  pageName: string,
  aggregation?: GridAggregationInitialState,
) {
  const initialized = useRef(false);
  const storageKey = `${key}_grid-state_${pageName}`;
  const storedState = JSON.parse(
    localStorage.getItem(storageKey) ?? "{}",
  ) as GridInitialStatePremium;
  const state = useMemo<GridInitialStatePremium>(
    () => ({
      ...storedState,
      preferencePanel: { open: false },
      filter: {
        filterModel: {
          ...storedState.filter?.filterModel,
          items:
            storedState.filter?.filterModel?.items.map((model) => ({
              ...model,
              value: !isNaN(new Date(model.value as string).getTime())
                ? new Date(model.value as string)
                : (model.value as string | number),
            })) ?? [],
        },
      },
      aggregation,
    }),
    [aggregation, storedState],
  );
  const ref = apiRef.current;

  useEffect(() => {
    if (!ref?.subscribeEvent) return;
    if (!ref?.getSortedRows) console.log(ref.getSortedRows());

    // Restore state on first ref load
    if (!initialized.current) {
      initialized.current = true;

      if (state?.aggregation) {
        try {
          // console.debug(`Restoring grid state for ${key}`, parsed);
          ref.restoreState(state);
        } catch (e) {
          console.warn(`Failed to restore grid state`, e);
        }
      }
    }

    const subs: VoidFunction[] = [];

    const save = () => {
      const state = ref.exportState();
      if (state) {
        // console.debug(`Storing grid state for ${key}`);
        localStorage.setItem(storageKey, JSON.stringify(state));
      }
    };

    const subscribe = <E extends GridEvents>(event: E) => {
      subs.push(ref.subscribeEvent(event, save));
    };

    subscribe("columnResizeStop");
    subscribe("columnOrderChange");
    subscribe("filterModelChange");
    subscribe("columnVisibilityModelChange");

    return () => {
      subs.forEach((unsub) => {
        unsub();
      });
    };
  }, [key, storageKey, ref, state]);

  return state;
}
