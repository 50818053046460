import { colors } from "../../../styles/figmaColors";
import { useTranslation } from "react-i18next";
import {
  AutoModeOutlined,
  LockOutlined,
  NotInterestedOutlined,
  TaskAltOutlined,
} from "../../materialUi/materialUi";
import { Tag } from "../Tag/Tag";

type Status = "ORDERED" | "ACTIVE" | "LOCKED" | "IN_OPPOSITION" | "ERROR";
const statusEnum = ["ORDERED", "ACTIVE", "LOCKED", "IN_OPPOSITION", "ERROR"];

const statusProps = {
  ORDERED: {
    backgroundColor: colors["colors/system/informative/informative_ultraLight"],
    textColor: colors["colors/system/informative/informative_normal"],
    iconColor: colors["colors/system/informative/informative_normal"],
    iconLeft: <AutoModeOutlined />,
  },
  ACTIVE: {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    textColor: colors["colors/system/success/success_normal"],
    iconColor: colors["colors/system/success/success_normal"],
    iconLeft: <TaskAltOutlined />,
  },
  IN_OPPOSITION: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    textColor: colors["colors/system/error/error_normal"],
    iconColor: colors["colors/system/error/error_normal"],
    iconLeft: <NotInterestedOutlined />,
  },
  ERROR: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    textColor: colors["colors/system/error/error_normal"],
    iconColor: colors["colors/system/error/error_normal"],
    iconLeft: <NotInterestedOutlined />,
  },
  LOCKED: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    iconColor: colors["colors/text/black"],
    iconLeft: <LockOutlined />,
  },
};

interface CategoryTagProps {
  status: string;
}

export const TableTagBadge = (props: CategoryTagProps) => {
  const { t } = useTranslation();
  const statusTranslated = statusEnum.find(
    (s) => t(`badges.status.${s}`) === props.status,
  );
  if (!statusTranslated) {
    return "";
  }
  const badgeStatus = statusProps[statusTranslated as Status];

  return (
    <Tag
      text={props.status}
      backgroundColor={badgeStatus.backgroundColor}
      textColor={badgeStatus.textColor}
      iconColor={badgeStatus.iconColor}
      //iconLeft={badgeStatus.iconLeft}
    />
  );
};
