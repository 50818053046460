import {
  Avatar,
  EmptyTableComponent,
  LaunchPage,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  SortingDirectionType,
  Spacer,
  TableDS,
  Tag,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { unreachable } from "../../../utils";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  managersListAsync,
  selectCancelPasswordCreationRequestStatus,
  selectDeactivateManagerStatus,
  selectManagersList,
  selectManagersListStatus,
  selectRelaunchPasswordCreationRequestStatus,
  UsersSlice,
} from "./usersSlice";
import { ManagerDataDisplay, ManagerDataDisplayRow } from "./usersAPI";
import { styled } from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { UserManagementSidePanel } from "./userManagementSidePanel";
import { statusColor } from "./lib";

export const UserManagementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const managersList = useAppSelector(selectManagersList);
  const managersListStatus = useAppSelector(selectManagersListStatus);
  const deactivateManagerStatus = useAppSelector(selectDeactivateManagerStatus);
  const cancelPasswordCreationRequestStatus = useAppSelector(
    selectCancelPasswordCreationRequestStatus,
  );
  const relaunchManagerStatus = useAppSelector(
    selectRelaunchPasswordCreationRequestStatus,
  );
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const [listDisplayed, setListDisplayed] = useState<
    ManagerDataDisplayRow[] | null
  >(null);

  useEffect(() => {
    if (deactivateManagerStatus === "success") {
      triggerToast(t("users.management.deactivated.success") || "", "valid");
      void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
    } else if (deactivateManagerStatus === "failed")
      triggerToast(t("users.management.deactivated.failure") || "", "error");
    void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
  }, [dispatch, deactivateManagerStatus, t]);

  useEffect(() => {
    if (relaunchManagerStatus === "success") {
      triggerToast(t("users.management.relaunch.success") || "", "valid");
      void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
    } else if (relaunchManagerStatus === "failed")
      triggerToast(t("users.management.relaunch.failure") || "", "error");
    void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
  }, [dispatch, relaunchManagerStatus, t]);

  useEffect(() => {
    if (cancelPasswordCreationRequestStatus === "success") {
      triggerToast(t("users.management.cancel.success") || "", "valid");
      void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
    } else if (cancelPasswordCreationRequestStatus === "failed")
      triggerToast(t("users.management.cancel.failure") || "", "error");
    void dispatch(UsersSlice.actions.resetDeactivatedManagerStatus());
  }, [dispatch, cancelPasswordCreationRequestStatus, t]);

  useEffect(() => {
    if (managersListStatus === "idle") {
      void dispatch(managersListAsync());
    }
  }, [dispatch, managersListStatus]);

  const sidePanelAction = useCallback(
    (props: Partial<ManagerDataDisplayRow>) => {
      navigate(`/home/users/management/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (managersList && managersListStatus === "success") {
      setListDisplayed(managersList);
    }
  }, [managersList, managersListStatus]);

  const sortUsersByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const vehiclesSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(vehiclesSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  if (params.tab !== "management") {
    return;
  }
  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const headersUserManagementList = {
    name: {
      text: t("users.management.column.name"),
      sortAction: () => sortUsersByAlphabeticalOrder("name"),
    },
    status: {
      text: t("users.management.column.status"),
      sortAction: () => sortUsersByAlphabeticalOrder("status"),
    },
    role: {
      text: t("users.management.column.role"),
      sortAction: () => sortUsersByAlphabeticalOrder("role"),
    },
  };

  const renderUserManagementList =
    (row: ManagerDataDisplayRow) => (key: keyof ManagerDataDisplay) => {
      switch (key) {
        case "name":
          return (
            <StyledRow>
              <Avatar
                size={"S"}
                color={"green"}
                firstName={row["firstName"] ? row["firstName"][0] : ""}
                lastName={row["lastName"] ? row["lastName"][0] : ""}
              />
              <Spacer x={0.5} />
              {row[key]}
            </StyledRow>
          );
        case "status":
          return (
            <Tag
              text={t(`users.management.status.${row[key]}`)}
              backgroundColor={statusColor[row[key]].backgroundColor}
              textColor={statusColor[row[key]].textColor}
            />
          );
        case "role":
          return (
            <Tag
              text={t(`users.management.role.${row[key]}`)}
              backgroundColor={
                colors["colors/system/success/success_ultraLight"]
              }
              textColor={colors["colors/system/success/success_normal"]}
            />
          );
      }
      unreachable(key);
    };

  return (
    <>
      <TableDS<keyof ManagerDataDisplay, ManagerDataDisplay>
        data={listDisplayed}
        headers={headersUserManagementList}
        render={renderUserManagementList}
        emptyContent={<EmptyTableComponent pageName={"users.management"} />}
        onClickRow={sidePanelAction}
      />
      <UserManagementSidePanel />
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;
