import {
  triggerToast,
  Assets,
  ButtonDS,
  SelectDS,
  AddressFormDS,
  ModalWithLeftPartDS,
} from "@qivia/ui";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectRulesList } from "../../rules/rulesSlice";
import { selectListAllVehiclesInDb } from "../../vehicles/vehiclesSlice";
import {
  CardsListDisplayed,
  CardsListType,
  ModalVehicleType,
} from "./../cardsAPI";
import {
  selectCardCreationStatus,
  selectCardsList,
  cardsAsync,
} from "./../cardsSlice";
import { AddressInput } from "./../index";
import { selectCompany } from "../../homeSlice";

const findVehiclesNotAssignedToCard = (
  vehicles: ModalVehicleType[],
  cardsList: CardsListType[] | CardsListDisplayed[],
) => {
  return vehicles.filter(
    (vehicle) =>
      cardsList.find(
        (card) =>
          (card.registrationNumber === vehicle.registrationNumber ||
            card.vehicleReference === vehicle.reference ||
            vehicle.status === "DEACTIVATED") &&
          card.status !== "IN_OPPOSITION",
      ) === undefined,
  );
};

export const AddCardModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const vehicles = useAppSelector(selectListAllVehiclesInDb);
  const rules = useAppSelector(selectRulesList);
  const company = useAppSelector(selectCompany);
  const cardCreationStatus = useAppSelector(selectCardCreationStatus);
  const cardsList: CardsListDisplayed[] = useAppSelector(selectCardsList).map(
    ({
      status,
      last4Digits,
      registrationNumber,
      ruleName,
      vehicleReference,
    }) => ({
      status,
      registrationNumber,
      last4Digits,
      rule: ruleName,
      vehicleReference,
    }),
  );

  const addressInitValues = useMemo(() => {
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: "FR",
    };
  }, []);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);
  const [vehicle, setVehicle] = useState<string>("");
  const [rule, setRule] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [addressInput, setAddressInput] =
    useState<AddressInput>(addressInitValues);

  const vehiclesNotAssignedToCard: ModalVehicleType[] =
    findVehiclesNotAssignedToCard(vehicles, cardsList);

  const optionVehicle = useMemo(
    () =>
      vehiclesNotAssignedToCard.map((vehicle) => {
        return {
          value: vehicle.uuid,
          label: [vehicle.registrationNumber, vehicle.reference]
            .filter((d) => d)
            .join(" - "),
        };
      }),
    [vehiclesNotAssignedToCard],
  );

  const optionRule = useMemo(
    () =>
      rules.map((rule) => {
        return {
          value: rule.uuid,
          label: rule.name,
        };
      }),
    [rules],
  );

  const valueOptionVehicle = useMemo(
    () => optionVehicle.find((option) => option.value === vehicle),
    [optionVehicle, vehicle],
  );
  const valueOptionRule = useMemo(
    () => optionRule.find((option) => option.value === rule),
    [optionRule, rule],
  );

  useEffect(() => {
    if (cardCreationStatus === "success") {
      triggerToast(t("cards.creation.success") || "", "valid");
    } else if (cardCreationStatus === "failed") {
      triggerToast(t("cards.creation.failure") || "", "error");
    }
  }, [cardCreationStatus, t]);

  useEffect(() => {
    if (vehicle && rule && isValidAddressForm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [isValidAddressForm, rule, vehicle]);

  useEffect(() => {
    addressInput.addressLine1 === "" ||
    addressInput.city === "" ||
    addressInput.postalCode === ""
      ? setIsValidAddressForm(false)
      : setIsValidAddressForm(true);
  }, [addressInput]);

  const resetValue = useCallback(() => {
    setAddressInput(addressInitValues);
    setRule("");
    setVehicle("");
    setDisplayError(false);
  }, [addressInitValues]);

  const onSubmit = useCallback(() => {
    if (!company) {
      return;
    }
    const cardData = {
      vehicle: vehicle,
      rule: rule,
      address: addressInput,
      companyUuid: company.uuid,
    };
    void dispatch(cardsAsync(cardData));
    resetValue();
    onCloseModal();
  }, [
    addressInput,
    company,
    dispatch,
    onCloseModal,
    resetValue,
    rule,
    vehicle,
  ]);

  const updateAddress = useCallback(
    (key: keyof AddressInput, value: string) => {
      const newValueState = {
        ...addressInput,
        [key]: value,
      };
      setAddressInput(newValueState);
    },
    [addressInput],
  );
  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!buttonDisabled) {
      onSubmit();
    }
  }, [buttonDisabled, onSubmit]);

  return (
    <ModalWithLeftPartDS
      title={t("cards.modal.title") || ""}
      visible={props.visible}
      tagText={t("cards.modal.tag") || ""}
      onClose={() => {
        resetValue();
        props.onCloseModal();
      }}
      leftPart={{
        button: (
          <ButtonDS
            text={t("cards.modal.add") || ""}
            onClick={clickToSubmit}
            disabled={buttonDisabled}
            format="fill"
            buttonType="primary"
          />
        ),
        icon: <Assets.NewQiviaCard />,
      }}
    >
      <SelectDS
        label={t("cards.modal.vehicle") || ""}
        placeholder={t("cards.modal.vehicle.placeholder") || ""}
        value={valueOptionVehicle}
        options={optionVehicle}
        onChange={(selectedOption) =>
          setVehicle(selectedOption ? selectedOption.value : "")
        }
        error={
          (displayError &&
            vehicle === "" &&
            t("cards.modal.error.selectInput")) ||
          undefined
        }
        allWidth
      />
      <SelectDS
        label={t("cards.modal.rule") || ""}
        placeholder={t("cards.modal.rule.placeholder") || ""}
        value={valueOptionRule}
        options={optionRule}
        onChange={(selectedOption) =>
          setRule(selectedOption ? selectedOption.value : "")
        }
        error={
          (displayError && rule === "" && t("cards.modal.error.selectInput")) ||
          undefined
        }
        allWidth
      />
      <AddressFormDS
        label={t("address.label.delivery") || ""}
        addressForm={addressInput}
        error={displayError}
        onChange={(key, value) => updateAddress(key, value)}
        onKeyDown={(key) => key === "Enter" && clickToSubmit()}
      />
    </ModalWithLeftPartDS>
  );
};
