export type SortingDirectionType = "asc" | "desc";

export type SortByDateType = {
  date: string;
  createdAt: string;
  contractStartDate: string | null;
  contractEndDate: string | null;
  dueDate: string;
};

export type SortByNumericType = {
  amount: number | null;
  mileage: number | null;
  mensualMileage: number | null;
  monthlyMileageKmVariation: number | null;
  mileageDeviationPercentage: number | null;
  projectionEndOfContract: number | null;
  mileageVariationEndContract: number | null;
  additionalCostEndContract: number | null;
  additionalCostPerKm: number | null;
  consumedContractDuration: number | null;
  remainingContractDuration: number | null;
  vehicleCount: number;
  totalAmount: number;
  closingBalance: number;
  latestMileage: number | null;
};

export type SortByAlphabeticalOrderType = {
  merchantName: string;
  registrationNumber: string | null;
  vehicle: string;
  rule: string;
  name: string;
  reference: string | null;
  driverName: string | null;
  mileageDeviation: string | null;
  fileName: string;
  vehicleInfo: string;
};

export const sortByDateTime = <T extends Partial<SortByDateType>>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column?: keyof SortByDateType,
): T[] => {
  const payloadSorted = [...initialPayload];
  const columnToSort = column ? column : "date";
  return payloadSorted.sort((a, b) => {
    if (!a[columnToSort] && !b[columnToSort]) return 0;
    if (!a[columnToSort]) return 1;
    if (!b[columnToSort]) return -1;
    return sortingDirection === "asc"
      ? new Date(a[columnToSort]!).getTime() -
          new Date(b[columnToSort]!).getTime()
      : new Date(b[columnToSort]!).getTime() -
          new Date(a[columnToSort]!).getTime();
  });
};

export const sortByNumeric = <T extends Partial<SortByNumericType>>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByNumericType,
) => {
  const payloadSorted = [...initialPayload];

  return payloadSorted.sort((a, b) => {
    if (a[column] === null && b[column] === null) return 0;
    if (a[column] === null) return 1;
    if (b[column] === null) return -1;
    return sortingDirection === "asc"
      ? a[column]! - b[column]!
      : b[column]! - a[column]!;
  });
};

export const sortByAlphabeticalOrder = <
  T extends Partial<SortByAlphabeticalOrderType>,
>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByAlphabeticalOrderType,
) => {
  const payloadSorted = [...initialPayload];

  return payloadSorted.sort((a, b) => {
    if (!a[column] && !b[column]) return 0;
    if (!a[column]) return 1;
    if (!b[column]) return -1;
    return sortingDirection === "asc"
      ? a[column]!.localeCompare(b[column]!)
      : b[column]!.localeCompare(a[column]!);
  });
};
