import { PageDS, Spacer, TabMenu, ToastContainer } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsVehiclesTab } from "./analyticsVehiclesTab";

export const Fleet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "analytics",
  );

  const tabs = [
    {
      name: "analytics",
      title: t("fleet.tab.analytics"),
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/fleet/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  return (
    <PageDS
      title={t("fleet.title")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Spacer y={0.5} />
        </>
      }
    >
      <AnalyticsVehiclesTab />
    </PageDS>
  );
};
