import { AmountLimit, RuleFormData, UpdateRulesAPI } from "./rulesAPI";
import { AddRuleModal } from "./rulesModals";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ruleDataAsync,
  rulesSlice,
  selectRuleData,
  selectRuleDataStatus,
  selectRulesList,
  updateRuleAsync,
} from "./rulesSlice";
import { useNavigate, useParams } from "react-router-dom";
import { rulesCategoryInfoList } from "./libDefaultDatas";
import { LaunchPage } from "@qivia/ui";

export const RuleUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routerParams = useParams();
  const rule = useAppSelector(selectRuleData);
  const rulesList = useAppSelector(selectRulesList);
  const ruleStatus = useAppSelector(selectRuleDataStatus);

  const historyRuleUuid = routerParams.id;

  const onSubmit = useCallback(
    (data: RuleFormData) => {
      if (!historyRuleUuid) {
        return;
      }
      const ruleData: UpdateRulesAPI = {
        ...data,
        uuid: historyRuleUuid,
      };
      void dispatch(updateRuleAsync(ruleData)).then(() =>
        navigate(`/home/rules/${data.name.replaceAll("/", "_")}`),
      );
    },
    [historyRuleUuid, dispatch, navigate],
  );

  useEffect(() => {
    if (!rule && historyRuleUuid) {
      void dispatch(ruleDataAsync(historyRuleUuid));
    }
  }, [dispatch, historyRuleUuid, rule]);

  useEffect(() => {
    if (rule && historyRuleUuid && rule.uuid !== historyRuleUuid) {
      dispatch(rulesSlice.actions.resetRuleData());
    }
  }, [dispatch, historyRuleUuid, navigate, rule]);

  const onModalClosed = useCallback(() => {
    navigate("/home/rules");
  }, [navigate]);

  if (!rule && ruleStatus !== "success") {
    return <LaunchPage />;
  } else if (!rule || !rulesList) {
    return;
  }

  const defaultExpensesLimit = {
    typeLimit: rule.typeLimit,
    amountLimit: rule.amountLimit,
  };
  const defaultGeographicControl = rule.allowedCountries;
  const defaultNotifications = {
    areSupportingDocumentsMandatory: rule.areSupportingDocumentsMandatory,
  };
  const defaultNationalHolidays = rule.nationalHolidays;
  const defaultAllowedPeriods = rule.allowedPeriods;
  const defaultAllowedCategories = rule.categories;
  const defaultRuleName = rule.name;
  const newValues = defaultAllowedCategories.map((category) => {
    return {
      ...category,
      amountLimit:
        category.amountLimit === 0 || category.amountLimit === null
          ? ""
          : (Number(category.amountLimit) as AmountLimit),
      typeLimit: category.typeLimit ?? "DAILY",
    };
  });
  const defaultMerchantGroups = {
    areOtherMerchantsAuthorized: rule.areOtherMerchantsAuthorized,
    unauthorizedMerchantGroups: rule.unauthorizedMerchantGroups,
  };

  return (
    <AddRuleModal
      onCloseModal={onModalClosed}
      onSubmit={onSubmit}
      values={{
        defaultRuleName,
        defaultGeographicControl,
        defaultExpensesLimit,
        defaultNationalHolidays,
        defaultAllowedPeriods,
        rulesCategoryInfoList,
        defaultAllowedCategories: newValues,
        defaultMerchantGroups,
        defaultNotifications,
      }}
      rulesList={rulesList.filter((rule) => rule.name !== rule?.name)}
    />
  );
};
