import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  checkHealthAsync,
  decodeToken,
  getCurrentCompany,
  loadAllCompaniesAsync,
  loadCompanyAsync,
  removeToken,
  selectAllCompanies,
  selectCompany,
  selectCurrentCompanyStatus,
  selectCurrentCompanyUuid,
  selectHealthCheckStatus,
  selectIsTokenDecoded,
  selectManager,
} from "./routes/home/homeSlice";
import { colors } from "@qivia/ui";

export const Layout = () => {
  const dispatch = useAppDispatch();
  const manager = useAppSelector(selectManager);
  const company = useAppSelector(selectCompany);
  const companies = useAppSelector(selectAllCompanies);
  const location = useLocation();
  const isTokenDecoded = useAppSelector(selectIsTokenDecoded);
  const healthCheckStatus = useAppSelector(selectHealthCheckStatus);
  const currentCompany = useAppSelector(selectCurrentCompanyUuid);
  const currentCompanyStatus = useAppSelector(selectCurrentCompanyStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenDecoded) dispatch(decodeToken());
  }, [dispatch, location, isTokenDecoded]);

  useEffect(() => {
    if (manager) {
      if (!manager.companyGroup) {
        dispatch(removeToken());
      }
      void dispatch(
        loadAllCompaniesAsync({ companyGroupUuid: manager.companyGroup }),
      );
    }
  }, [manager, dispatch]);

  useEffect(() => {
    if (manager && (!manager.status || !manager.role)) {
      dispatch(removeToken());
    }
  }, [manager, dispatch]);

  useEffect(() => {
    if (!currentCompany) dispatch(getCurrentCompany());
  }, [currentCompany, dispatch]);

  useEffect(() => {
    if (
      !company &&
      companies.length > 0 &&
      currentCompanyStatus === "success"
    ) {
      if (currentCompany) {
        void dispatch(loadCompanyAsync({ uuid: currentCompany }));
      } else {
        void dispatch(loadCompanyAsync({ uuid: companies[0].uuid }));
      }
    }
  }, [companies, company, currentCompany, currentCompanyStatus, dispatch]);

  useEffect(() => {
    if (manager) {
      if (healthCheckStatus === "idle") void dispatch(checkHealthAsync());
    }
  }, [healthCheckStatus, dispatch, navigate, manager, location]);

  if (location.pathname === "/") return <Navigate to="/home" />;

  return (
    <StyledContainer>
      <Outlet />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.background};
`;
