import {
  Assets,
  ButtonDS,
  DialogModalDS,
  MessageTooltip,
  RowElement,
  Spacer,
  Tag,
  TextCapitalized,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InOppositionOptions } from "./StepsToInOppositionModal";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  createBadgesAsync,
  replacementBadgeAsync,
  updateBadgeStatusAsync,
} from "../badgesSlice";
import { selectCompany } from "../../homeSlice";
import { BadgeAddressInput } from "../badgesAPI";

export const BadgeOverviewModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  badge: { number: string; uuid: string };
  vehicle: {
    registrationNumber: string;
    reference: string;
    uuid: string;
  };
  option: InOppositionOptions;
  address: BadgeAddressInput | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);

  const { onCloseModal, address } = props;

  const addressStringify = useMemo(() => {
    return (
      address &&
      address.name +
        ", " +
        address.addressLine1 +
        (address.addressLine2 && " " + address.addressLine2) +
        ", " +
        address.postalCode +
        " " +
        address.city +
        ", FRANCE"
    );
  }, [address]);

  const actionType =
    props.option === "IN_OPPOSITION" || props.option === "RETURN"
      ? "IN_OPPOSITION"
      : "ORDER";

  const onSubmit = useCallback(() => {
    if (!company) {
      return;
    }
    switch (props.option) {
      case "IN_OPPOSITION":
        void dispatch(
          updateBadgeStatusAsync({
            badgeUuid: props.badge.uuid,
            status: "IN_OPPOSITION",
            companyUuid: company.uuid,
          }),
        );
        break;
      case "IN_OPPOSITION_AND_ORDER":
        void dispatch(
          updateBadgeStatusAsync({
            badgeUuid: props.badge.uuid,
            status: "IN_OPPOSITION",
            companyUuid: company.uuid,
          }),
        );
        void dispatch(
          createBadgesAsync({
            request: {
              vehicles: [
                {
                  uuid: props.vehicle.uuid,
                  registrationNumber: props.vehicle.registrationNumber,
                  reference: props.vehicle.reference,
                },
              ],
              deliveryAddress: props.address,
            },
            companyUuid: company.uuid,
          }),
        );
        break;
      case "RETURN":
        void dispatch(
          updateBadgeStatusAsync({
            badgeUuid: props.badge.uuid,
            status: "RETURNED",
            companyUuid: company.uuid,
          }),
        );
        break;
      case "RETURN_AND_REPLACE":
        void dispatch(
          replacementBadgeAsync({
            badgeUuid: props.badge.uuid,
            companyUuid: company.uuid,
          }),
        );
        break;
    }
    onCloseModal();
  }, [
    company,
    dispatch,
    onCloseModal,
    props.address,
    props.badge.uuid,
    props.option,
    props.vehicle.reference,
    props.vehicle.registrationNumber,
    props.vehicle.uuid,
  ]);

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={onCloseModal}
      title={t("badges.overviewModal.title")}
      width={40}
      bottomButton={
        <ButtonDS
          text={t(`badges.overviewModal.button.${actionType}`)}
          format={"fill"}
          buttonType={"primary"}
          onClick={onSubmit}
        />
      }
    >
      <StyledModalContainer>
        <StyledTitle>
          <TextCapitalized>
            {t(`badges.overviewModal.${actionType}.title`)}
          </TextCapitalized>
        </StyledTitle>
        <Spacer y={1} />
        <RowElement
          text={
            <StyledElement>
              <StyledAssets>
                <Assets.BadgeLogo />
              </StyledAssets>
              <Spacer x={1} />
              {props.badge.number}
            </StyledElement>
          }
          rightElement={
            <StyledElement>
              {props.vehicle.registrationNumber}
              {props.vehicle.reference && (
                <>
                  <Spacer x={1} />
                  <Tag
                    text={props.vehicle.reference}
                    textColor={colors["colors/text/black"]}
                    borderColor={colors["colors/borders/cells/cells"]}
                  />
                </>
              )}
            </StyledElement>
          }
          size={"XL"}
        />
        {(props.option === "IN_OPPOSITION_AND_ORDER" ||
          props.option === "RETURN_AND_REPLACE") &&
          addressStringify && (
            <DeliveryAddress addressStringify={addressStringify} />
          )}
        {(props.option === "RETURN" ||
          props.option === "RETURN_AND_REPLACE") && (
          <ReturnAddress option={props.option} />
        )}
        <Spacer y={1.5} />
        <MessageTooltip
          title={t(`badges.overviewModal.${props.option}.messageTooltip.title`)}
          text={t(`badges.overviewModal.${props.option}.messageTooltip.text`)}
          type={"INFORMATIVE"}
        />
      </StyledModalContainer>
    </DialogModalDS>
  );
};

export const DeliveryAddress = (props: { addressStringify: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Spacer y={1.5} />
      <StyledTitle>
        <TextCapitalized>
          {t(`badges.overviewModal.deliveryAddress.title`)}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={1} />
      <RowElement text={props.addressStringify} size={"L"} />
    </>
  );
};

export const ReturnAddress = (props: { option: InOppositionOptions }) => {
  const { t } = useTranslation();
  return (
    <>
      <Spacer y={1.5} />
      <StyledTitle>
        <TextCapitalized>
          {t(`badges.overviewModal.returnAddress.title`)}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={1} />
      <RowElement
        text={t(`badges.overviewModal.returnAddress.text.${props.option}`)}
        subText={t(
          `badges.overviewModal.returnAddress.subText.${props.option}`,
        )}
        size={"L"}
      />
    </>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const StyledTitle = styled.div`
  display: flex;
  width: 100%;
  ${typographies["Header/H4"]}
`;

const StyledAssets = styled.div`
  & svg {
    width: 4rem;
  }
`;

const StyledElement = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;
