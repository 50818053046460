import { AddressFormDS, ButtonDS, DialogModalDS } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { BadgeAddressInput } from "../badgesAPI";

export const BadgeDeliveryAddressModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  setAddressFilled: (value: BadgeAddressInput) => void;
}) => {
  const { t } = useTranslation();
  const { setAddressFilled, onCloseModal } = props;

  const addressInitValues = useMemo(() => {
    return {
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: "FR",
    };
  }, []);

  const [address, setAddress] = useState<BadgeAddressInput>(addressInitValues);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const hasError = useMemo(() => {
    return (
      address.addressLine1 === "" ||
      address.city === "" ||
      address.postalCode === "" ||
      address.name === ""
    );
  }, [address.addressLine1, address.city, address.name, address.postalCode]);

  const updateAddress = useCallback(
    (key: keyof BadgeAddressInput, value: string) => {
      const newValueState = {
        ...address,
        [key]: value,
      };
      setAddress(newValueState);
    },
    [address],
  );

  const resetValue = useCallback(() => {
    setAddress(addressInitValues);
  }, [addressInitValues]);

  const onSubmit = useCallback(() => {
    if (hasError) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      setAddressFilled(address);
      resetValue();
    }
  }, [address, hasError, setAddressFilled, resetValue]);

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={onCloseModal}
      title={t("badges.deliveryModal.title")}
      width={40}
      bottomButton={
        <ButtonDS
          text={t(`badges.deliveryModal.button`)}
          format={"fill"}
          buttonType={"primary"}
          onClick={onSubmit}
          disabled={hasError}
        />
      }
      deactivateClickOutsideSidePanel
    >
      <AddressFormDS
        label={""}
        addressForm={address}
        error={displayError}
        onChange={(key, value) => updateAddress(key, value)}
        onKeyDown={(key) => key === "Enter" && onSubmit()}
        hasName
      />
    </DialogModalDS>
  );
};
