export const getConsumedContractDurationMonth = (
  contractStartDate: null | string,
  contractDurationMonth: number | null,
): number => {
  if (!contractStartDate || !contractDurationMonth) return 0;
  const now = new Date();
  let consumedContractDuration =
    now.getTime() - new Date(contractStartDate).getTime();
  consumedContractDuration =
    consumedContractDuration < 0 ? 0 : consumedContractDuration;

  const consumedContractDurationMonth = Number(
    (consumedContractDuration / (1000 * 60 * 60 * 24 * 30.5)).toFixed(0),
  );

  return consumedContractDurationMonth > contractDurationMonth
    ? contractDurationMonth
    : consumedContractDurationMonth;
};

export const getRemainingContractDurationMonth = (
  contractDurationMonth: number | null,
  consumedContractDurationMonth: number,
) => {
  if (contractDurationMonth === null) return null;
  return contractDurationMonth - consumedContractDurationMonth;
};

export const getContractDurationMonth = (
  contractStartDate: null | string,
  contractEndDate: null | string,
) => {
  if (contractStartDate === null || contractEndDate === null) return null;
  if (contractStartDate >= contractEndDate) return null;

  const contractDuration =
    new Date(contractEndDate).getTime() - new Date(contractStartDate).getTime();
  return Number((contractDuration / (1000 * 60 * 60 * 24 * 30.5)).toFixed(0));
};

export const getMensualMileage = (
  mileage: null | number,
  contractStartDate: null | string,
) => {
  if (mileage === null || contractStartDate === null) return null;

  const now = new Date();
  const duration = now.getTime() - new Date(contractStartDate).getTime();
  const durationMonths = Number(
    (duration / (1000 * 60 * 60 * 24 * 30.5)).toFixed(0),
  );

  return Number((mileage / durationMonths).toFixed(0));
};

export const getProjectionEndOfContract = (
  mensualMileage: null | number,
  contractDurationMonth: null | number,
) => {
  if (mensualMileage === null || contractDurationMonth === null) return null;

  return Number((mensualMileage * contractDurationMonth).toFixed(0));
};

export const getMileageVariationEndContract = (
  contractMileage: number | null | undefined,
  projectionEndOfContract: null | number,
) => {
  if (
    contractMileage === null ||
    contractMileage === undefined ||
    projectionEndOfContract === null
  )
    return null;
  return projectionEndOfContract - contractMileage;
};

export const getAdditionalCostEndContract = (
  mileageVariationEndContract: number | null,
  additionalCostPerKm: number | null,
) => {
  if (mileageVariationEndContract === null || !additionalCostPerKm) return null;

  const result = Number(
    (mileageVariationEndContract * additionalCostPerKm).toFixed(2),
  );
  return result < 0 ? 0 : result;
};

export const getContractMensualMileage = (
  contractMileage: null | number | undefined,
  contractDurationMonth: null | number,
) => {
  if (
    contractMileage === null ||
    contractMileage === undefined ||
    contractDurationMonth === null
  )
    return null;

  return Number((contractMileage / contractDurationMonth).toFixed(0));
};

export const getMileageDeviationPercentage = (
  mensualMileage: number | null,
  mensualContractMileage: null | number,
) => {
  if (mensualMileage === null || mensualContractMileage === null) return null;
  return Number(
    (
      ((mensualMileage - mensualContractMileage) / mensualContractMileage) *
      100
    ).toFixed(2),
  );
};

export const getMonthlyMileageDeviation = (
  mensualMileage: number | null,
  mensualContractMileage: null | number,
) => {
  if (mensualMileage === null || mensualContractMileage === null) return null;
  return mensualMileage - mensualContractMileage;
};

export const getDate = (value: string) => {
  if (!value) return value;
  const [day, month, year] = value.split("/");
  return new Date(`${month}/${day}/${year}`);
};

export const getDateTime = (value: string) => {
  if (!value) return value;
  const [day, month, rest] = value.split("/");
  const [year, restHours] = rest.split(" à ");
  const [hour, minute] = restHours.split("h");

  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
  );
};
