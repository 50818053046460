import { ButtonDS, DialogModalDS, TextCapitalized } from "@qivia/ui";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deactivateVehicleAsync } from "../vehiclesSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate } from "react-router-dom";
import { selectCompany } from "../../homeSlice";

export const DeactivateVehicleModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  vehicleUuid: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company = useAppSelector(selectCompany);

  const deactivateVehicle = useCallback(() => {
    if (!company) return;
    void dispatch(
      deactivateVehicleAsync({
        vehicleUuid: props.vehicleUuid,
        companyUuid: company.uuid,
      }),
    );
    props.onCloseModal();
    navigate(`/home/vehicles`);
  }, [dispatch, navigate, props, company]);

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={() => {
        props.onCloseModal();
      }}
      title={t("vehicles.deactivate.modal.title")}
    >
      <StyledModalContainer>
        <TextCapitalized>
          {t("vehicles.deactivate.modal.content")}
        </TextCapitalized>
        <Spacer y={1.5} />
        <StyledButtons>
          <ButtonDS
            text={t("vehicles.deactivate.modal.button.cancel")}
            onClick={() => {
              props.onCloseModal();
            }}
            buttonType={"secondary"}
            format={"fill"}
          />
          <Spacer x={1.5} />
          <ButtonDS
            text={t("vehicles.deactivate.modal.button.confirm")}
            onClick={() => deactivateVehicle()}
            buttonType={"primary"}
            format={"fill"}
          />
        </StyledButtons>
      </StyledModalContainer>
    </DialogModalDS>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${typographies["Body/S"]}
  color: ${colors["colors/text/lightGrey"]};
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
`;
