import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  CompanySepaDebitInfo,
  CreateExternalBankAccount,
} from "./sepaDebitApi";

export interface SepaDebitState {
  createExternalBankAccountStatus: QueryStatus;
  getCompanyByAccountProviderIdStatus: QueryStatus;
  companySepaDebitInfo: CompanySepaDebitInfo;
  isStripeConnectMandateSigned: boolean | null;
  isStripePlatformMandateSigned: boolean | null;
}

const initialState: SepaDebitState = {
  createExternalBankAccountStatus: "idle",
  getCompanyByAccountProviderIdStatus: "idle",
  companySepaDebitInfo: {
    connectMandateStatus: "PENDING_SIGNATURE",
    connectClientSecret: "",
    connectSetupIntent: "",
    platformMandateStatus: "PENDING_SIGNATURE",
    platformClientSecret: "",
    platformSetupIntent: "",
  },
  isStripeConnectMandateSigned: null,
  isStripePlatformMandateSigned: null,
};

export const createExternalBankAccountAsync = createAsyncThunk(
  "sepa_debit/external_bank_account",
  async (payload: CreateExternalBankAccount) => {
    const axios = authAxios();
    await axios.post(`/sepa_debit/external_bank_account`, payload);
  },
);

export const getCompanySepaDebitInfoByAccountProviderIdAsync = createAsyncThunk(
  "sepa_debit/company",
  async (payload: { accountProviderId: string }) => {
    const axios = authAxios();
    const response = await axios.get<CompanySepaDebitInfo>(
      `/sepa_debit/company_provider_id/${payload.accountProviderId}`,
    );
    return response.data;
  },
);

export const sepaDebitSlice = createSlice({
  name: "sepaDebit",
  initialState,
  reducers: {
    resetExternalBankAccountStatus(state) {
      state.createExternalBankAccountStatus = "idle";
    },
    setConnectSepaMandateSigned(state, action: PayloadAction<boolean | null>) {
      state.isStripeConnectMandateSigned = action.payload;
    },
    setPlatformSepaMandateSigned(state, action: PayloadAction<boolean | null>) {
      state.isStripePlatformMandateSigned = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExternalBankAccountAsync.pending, (state) => {
        state.createExternalBankAccountStatus = "processing";
      })
      .addCase(createExternalBankAccountAsync.fulfilled, (state) => {
        state.createExternalBankAccountStatus = "success";
      })
      .addCase(createExternalBankAccountAsync.rejected, (state) => {
        state.createExternalBankAccountStatus = "failed";
      })
      .addCase(
        getCompanySepaDebitInfoByAccountProviderIdAsync.pending,
        (state) => {
          state.getCompanyByAccountProviderIdStatus = "processing";
        },
      )
      .addCase(
        getCompanySepaDebitInfoByAccountProviderIdAsync.fulfilled,
        (state, action) => {
          state.getCompanyByAccountProviderIdStatus = "success";
          state.companySepaDebitInfo = action.payload;
        },
      )
      .addCase(
        getCompanySepaDebitInfoByAccountProviderIdAsync.rejected,
        (state) => {
          state.getCompanyByAccountProviderIdStatus = "failed";
        },
      );
  },
});

export const selectCreateExternalBankAccountStatus = (state: RootState) =>
  state.sepaDebit.createExternalBankAccountStatus;

export const selectGetCompanySepaDebitInfoByAccountProviderIdStatus = (
  state: RootState,
) => state.sepaDebit.getCompanyByAccountProviderIdStatus;

export const selectCompanySepaDebitInfo = (state: RootState) =>
  state.sepaDebit.companySepaDebitInfo;

export const selectIsStripeConnectMandateSigned = (state: RootState) =>
  state.sepaDebit.isStripeConnectMandateSigned;

export const selectIsStripePlatformMandateSigned = (state: RootState) =>
  state.sepaDebit.isStripePlatformMandateSigned;

export default sepaDebitSlice.reducer;
