import { hasIdentificationNumberFormat, LaunchPage } from "@qivia/ui";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  AddressInput,
  AddressPage,
} from "../../register/registerPages/addressPage";
import { BusinessTypePage } from "../../register/registerPages/businessTypePage";
import { BusinessType } from "../../stripe/stripeAPI";
import {
  selectStripeToken,
  stripeTokenCreationAsync,
} from "../../stripe/stripeSlice";
import { selectIsTokenDecoded, selectManager } from "../homeSlice";
import { companyAsync, selectCompanyCreationStatus } from "./companiesSlice";
import { BusinessTypeForDB } from "./companiesApi";

export const CompanyCreation = () => {
  const navigate = useNavigate();
  const companyCreationStatus = useAppSelector(selectCompanyCreationStatus);
  const stripeToken = useAppSelector(selectStripeToken);
  const [submitForm, setSubmitForm] = useState(false);
  const [businessType, setBusinessType] = useState<BusinessType | undefined>(
    undefined,
  );
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);
  const [route, setRoute] = useState<string>("businessType");
  const [addressInput, setAddressInput] = useState<AddressInput>({
    company: "",
    identificationNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    country: "FR",
  });
  const manager = useAppSelector(selectManager);

  const isTokenDecoded = useAppSelector(selectIsTokenDecoded);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !stripeToken &&
      isValidAddressForm &&
      route === "submit" &&
      submitForm
    ) {
      const stripeTokenCreationData = {
        business_type: businessType as BusinessType,
        company: {
          name: addressInput.company,
          address: {
            line1: addressInput.addressLine1 + addressInput.addressLine2,
            city: addressInput.city,
            postal_code: addressInput.postalCode,
          },
          tax_id: addressInput.identificationNumber,
        },
        tos_shown_and_accepted: true,
      };
      void dispatch(stripeTokenCreationAsync(stripeTokenCreationData));
    }
  }, [
    dispatch,
    submitForm,
    addressInput,
    businessType,
    isValidAddressForm,
    route,
    stripeToken,
  ]);

  useEffect(() => {
    if (stripeToken !== "" && submitForm && manager) {
      const registerData = {
        companyName: addressInput.company,
        address: addressInput,
        identificationNumber: addressInput.identificationNumber,
        token: stripeToken,
        businessType: businessType?.toUpperCase() as BusinessTypeForDB,
        companyGroupUuid: manager.companyGroup,
      };
      void dispatch(companyAsync(registerData));
    }
  }, [
    stripeToken,
    addressInput,
    dispatch,
    submitForm,
    route,
    businessType,
    manager,
  ]);

  const areValidAddressInputs = useMemo(() => {
    return !Object.entries(addressInput).some(
      ([key, value]) =>
        (key !== "addressLine2" && value === "") ||
        (key === "identificationNumber" &&
          !hasIdentificationNumberFormat(value)),
    );
  }, [addressInput]);

  useEffect(() => {
    setIsValidAddressForm(areValidAddressInputs);
  }, [areValidAddressInputs]);

  useEffect(() => {
    if (companyCreationStatus === "failed" && route === "submit") {
      setRoute("address");
    }
  }, [companyCreationStatus, route]);

  useEffect(() => {
    if (companyCreationStatus === "success" || route === "qiviaWebSite") {
      navigate("/home");
    }
  }, [companyCreationStatus, navigate, route]);

  const nextPage = useCallback((route: string) => {
    setRoute(route);
    if (route === "submit") setSubmitForm(true);
  }, []);

  const onClickBusinessType = useCallback(
    (type: BusinessType) => {
      setBusinessType(type);
      nextPage("address");
    },
    [nextPage],
  );

  const previousPage = useCallback((route: string) => {
    setSubmitForm(false);
    setRoute(route);
  }, []);

  return (
    <>
      {isTokenDecoded && (
        <>
          {route === "businessType" ? (
            <BusinessTypePage
              nextPage={onClickBusinessType}
              previousPage={() => previousPage("qiviaWebSite")}
            />
          ) : route === "address" ? (
            <AddressPage
              nextPage={() => nextPage("submit")}
              previousPage={() => previousPage("businessType")}
              businessType={businessType}
              addressInput={addressInput}
              onChange={(e) => setAddressInput(e)}
              isValidForm={isValidAddressForm}
            />
          ) : (
            <LaunchPage />
          )}
        </>
      )}
    </>
  );
};
