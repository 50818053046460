import { Stack } from "@mui/material";
import {
  GridColumnMenuProps,
  GridColumnMenuSortItem,
} from "@mui/x-data-grid-premium";
import React from "react";

export default function CustomColumnMenu(props: GridColumnMenuProps) {
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };
  return (
    <React.Fragment>
      <Stack px={0.5} py={0.5}>
        <GridColumnMenuSortItem {...itemProps} />
      </Stack>
    </React.Fragment>
  );
}
