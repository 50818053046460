import {
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  TableTagVehicle,
  Tag,
  unreachable,
  TextCapitalized,
  TableDS,
  getDeliveryDate,
  ButtonDS,
  AddressFormDS,
  Spacer,
  RowElement,
  EditOutlined,
  CloseOutlined,
} from "@qivia/ui";
import { BadgeLogo, EmptyTable } from "@qivia/ui/src/assets/assets";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  ModalVehicleType,
  BadgesListDisplayed,
  BadgesList,
  VehiclesListToOrderDisplayedType,
  VehiclesListToOrderType,
  VehicleData,
  BadgeAddressInput,
} from "./../badgesAPI";
import { createBadgesAsync, selectBadgesList } from "./../badgesSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { selectListAllVehiclesInDb } from "../../vehicles/vehiclesSlice";
import { selectCompany } from "../../homeSlice";
import { selectCompanyAddress } from "../../cards/cardsSlice";

const findVehiclesNotAssignedToBadge = (
  vehicles: ModalVehicleType[],
  badgesList: BadgesList[] | BadgesListDisplayed[],
) => {
  return vehicles.filter(
    (vehicle) =>
      badgesList.find(
        (badge) =>
          (badge.registrationNumber === vehicle.registrationNumber ||
            badge.reference === vehicle.reference ||
            vehicle.status === "DEACTIVATED") &&
          badge.status !== "IN_OPPOSITION",
      ) === undefined,
  );
};

export const AddMultipeBadgesModal = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const company = useAppSelector(selectCompany);
  const vehicles = useAppSelector(selectListAllVehiclesInDb);
  const badgesList = useAppSelector(selectBadgesList);
  const companyAddress = useAppSelector(selectCompanyAddress);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [hasAddressCreated, setHasAddressCreated] = useState<boolean>(false);
  const [rowsChecked, setRowsChecked] = useState<
    Array<VehiclesListToOrderType>
  >([]);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);

  const addressInitValues = useMemo(() => {
    return {
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: "FR",
    };
  }, []);
  const [addressInput, setAddressInput] =
    useState<BadgeAddressInput>(addressInitValues);

  const vehiclesNotAssignedToBadge: ModalVehicleType[] =
    findVehiclesNotAssignedToBadge(vehicles, badgesList);

  const vehiclesListDisplayed = useMemo(() => {
    const vehiclesNotAssignedToBadgeWithReference: VehiclesListToOrderType[] =
      [];
    const vehiclesNotAssignedToBadgeWithoutReference: VehiclesListToOrderType[] =
      [];

    vehiclesNotAssignedToBadge.map((vehicle) => {
      if (vehicle.reference) {
        vehiclesNotAssignedToBadgeWithReference.push(vehicle);
      } else {
        vehiclesNotAssignedToBadgeWithoutReference.push(vehicle);
      }
    });

    const vehiclesNotAssignedToBadgeSortedByReference = sortByAlphabeticalOrder(
      vehiclesNotAssignedToBadgeWithReference,
      "asc",
      "reference" as keyof SortByAlphabeticalOrderType,
    );

    return [
      ...vehiclesNotAssignedToBadgeSortedByReference,
      ...vehiclesNotAssignedToBadgeWithoutReference,
    ].map((vehicle) => {
      return {
        uuid: vehicle.uuid,
        registrationNumber: vehicle.registrationNumber,
        reference: vehicle.reference,
        status: vehicle.status,
      };
    });
  }, [vehiclesNotAssignedToBadge]);

  useEffect(() => {
    if (rowsChecked.length > 0 && isValidAddressForm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [isValidAddressForm, rowsChecked]);

  useEffect(() => {
    hasAddressCreated &&
    (addressInput.addressLine1 === "" ||
      addressInput.city === "" ||
      addressInput.postalCode === "" ||
      addressInput.name === "")
      ? setIsValidAddressForm(false)
      : setIsValidAddressForm(true);
  }, [addressInput, hasAddressCreated]);

  const resetValue = useCallback(() => {
    setRowsChecked([]);
    setAddressInput(addressInitValues);
    setHasAddressCreated(false);
    setDisplayError(false);
  }, [addressInitValues]);

  const updateAddress = useCallback(
    (key: keyof BadgeAddressInput, value: string) => {
      const newValueState = {
        ...addressInput,
        [key]: value,
      };
      setAddressInput(newValueState);
    },
    [addressInput],
  );

  const onSubmit = useCallback(() => {
    const vehiclesList: VehicleData[] = rowsChecked
      .map((row) => {
        if (row.uuid) {
          return {
            uuid: row.uuid,
            registrationNumber: row.registrationNumber,
            reference: row.reference,
          };
        } else {
          return "";
        }
      })
      .filter((vehicle) => vehicle !== "");

    if (!company || vehiclesList.length === 0) {
      return;
    }

    const badgesData = {
      request: {
        vehicles: vehiclesList,
        deliveryAddress: hasAddressCreated ? addressInput : null,
      },
      companyUuid: company.uuid,
    };
    void dispatch(createBadgesAsync(badgesData));
    resetValue();
    onCloseModal();
  }, [
    addressInput,
    company,
    dispatch,
    hasAddressCreated,
    onCloseModal,
    resetValue,
    rowsChecked,
  ]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!buttonDisabled) {
      onSubmit();
    }
  }, [buttonDisabled, onSubmit]);

  const render =
    (row: VehiclesListToOrderType) =>
    (key: keyof VehiclesListToOrderDisplayedType) => {
      switch (key) {
        case "status":
          return <TableTagVehicle status={row[key]} />;
        case "reference":
          return row[key] ? (
            <Tag
              textColor={colors["colors/text/black"]}
              backgroundColor={
                colors["colors/surfaces/background/background_level0"]
              }
              text={row[key]}
              borderColor={colors["colors/borders/cells/cells"]}
            />
          ) : (
            "-"
          );
        case "registrationNumber":
          return row[key] ?? "-";
      }
      unreachable(key);
    };

  const headers = {
    registrationNumber: {
      text: "",
    },
    reference: {
      text: "",
    },
    status: {
      text: "",
    },
  };

  if (!company) {
    return;
  }

  return (
    <StyledOrderBadges>
      <StyledTable>
        {displayError && rowsChecked.length === 0 && (
          <StyledErrorMessage>
            <TextCapitalized>
              {t("badges.modal.multipleBadges.error.listEmpty")}
            </TextCapitalized>
            <Spacer y={1} />
          </StyledErrorMessage>
        )}
        <TableDS<
          keyof VehiclesListToOrderDisplayedType,
          VehiclesListToOrderDisplayedType
        >
          data={vehiclesListDisplayed}
          headers={headers}
          render={render}
          rowsChecked={rowsChecked}
          setRowsChecked={setRowsChecked}
          height={34.5}
          headerOverlayed={
            <StyledHeader>
              <BadgeLogo />
              <Spacer x={1} />
              <div>
                {t("badges.modal.multipleBadges.table.header", {
                  number: rowsChecked.length,
                })}
              </div>
            </StyledHeader>
          }
          emptyContent={
            <StyledFlex>
              <Spacer x={1.5} />
              <StyledEmptyTable>
                <Spacer y={2} />
                <EmptyTable />
                <Spacer y={1.5} />
                <StyledTitleEmptyTable>
                  <TextCapitalized>
                    {t("badges.modal.multipleBadges.listEmpty.title")}
                  </TextCapitalized>
                </StyledTitleEmptyTable>
                <Spacer y={1} />
                <StyledSubText>
                  <TextCapitalized>
                    {t("badges.modal.multipleBadges.listEmpty.body")}
                  </TextCapitalized>
                </StyledSubText>
                <Spacer y={2} />
              </StyledEmptyTable>
              <Spacer x={1.5} />
            </StyledFlex>
          }
        />
        <Spacer y={4} />
      </StyledTable>
      <Spacer x={2.5} />
      <StyledRightColumn>
        <StyledSelectDataImport>
          <Spacer x={1.5} />
          <StyledColumn>
            <Spacer y={1.5} />
            {hasAddressCreated ? (
              <StyledFlex>
                <AddressFormDS
                  label={t("address.label.delivery") || ""}
                  addressForm={addressInput}
                  error={displayError}
                  onChange={(key, value) => updateAddress(key, value)}
                  onKeyDown={(key) => key === "Enter" && clickToSubmit()}
                  hasName
                />
                <StyledClose>
                  <CloseOutlined onClick={() => setHasAddressCreated(false)} />
                </StyledClose>
              </StyledFlex>
            ) : (
              <>
                <StyledTitle>
                  <TextCapitalized>{t(`badges.modal.address`)}</TextCapitalized>
                </StyledTitle>
                <Spacer y={1} />
                <RowElement
                  text={company.name}
                  subText={companyAddress}
                  size={"L"}
                  rightElement={
                    <StyledElement>
                      <EditOutlined
                        onClick={() => setHasAddressCreated(true)}
                      />
                    </StyledElement>
                  }
                />
              </>
            )}
            <Spacer y={1} />
            <StyledTitle>
              <TextCapitalized>
                {t("badges.modal.multipleBadges.deliveryDate.title") || ""}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={0.75} />
            <StyledText>{getDeliveryDate(3, 4)}</StyledText>
            <Spacer y={0.25} />
            <Spacer y={1.5} />
          </StyledColumn>
          <Spacer x={1.5} />
        </StyledSelectDataImport>
        <Spacer y={1.5} />
        <StyledCenter>
          <ButtonDS
            format="hug"
            buttonType="primary"
            text={t("badges.modal.multipleBadges.submit") || ""}
            onClick={clickToSubmit}
            disabled={buttonDisabled}
          />
        </StyledCenter>
        <Spacer y={1.5} />
      </StyledRightColumn>
    </StyledOrderBadges>
  );
};

const StyledEmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 34.6875rem;
  align-items: center;
  text-align: center;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledClose = styled.div`
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledTitleEmptyTable = styled.div`
  display: flex;
  ${typographies["Header/H1"]};
  color: ${colors["colors/text/black"]};
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledHeader = styled.div`
  display: flex;
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
  align-items: center;
  & svg {
    width: 3rem;
    height: 3rem;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  ${typographies["Body/M"]};
  color: ${colors["colors/text/black"]};
`;

const StyledText = styled.div`
  display: flex;
  ${typographies["Body/L"]};
  color: ${colors["colors/text/black"]};
`;

const StyledSubText = styled.div`
  display: flex;
  ${typographies["Body/S"]};
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledOrderBadges = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 39rem;
  height: 100%;
`;

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSelectDataImport = styled.div`
  display: flex;
  justify-content: center;
  width: 25rem;
  height: fit-content;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  background-color: ${colors["colors/surfaces/background/background_level2"]};
  border-radius: 0.5rem;
`;

const StyledElement = styled.div`
  cursor: pointer;
  & svg {
    height: 1.2rem;
  }
`;
