import { colors } from "../../../styles/figmaColors";
import { useTranslation } from "react-i18next";
import { Tag } from "../Tag/Tag";

type Status = "ACCEPTED" | "REJECTED" | "SUSPICIOUS" | "PENDING" | "CANCELED";
const statusEnum = [
  "ACCEPTED",
  "REJECTED",
  "SUSPICIOUS",
  "PENDING",
  "CANCELED",
];
const statusProps = {
  ACCEPTED: {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    textColor: colors["colors/system/success/success_normal"],
    text: "accepted",
  },
  REJECTED: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    textColor: colors["colors/system/error/error_normal"],
    text: "rejected",
  },
  SUSPICIOUS: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    text: "suspicious",
  },
  NO_SUPPORTING_DOCUMENT: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    text: "noSupportingDocument",
    colorHover: colors["colors/surfaces/background/background_level0"],
  },
  PENDING: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    textColor: colors["colors/text/black"],
    text: "pending",
    colorHover: colors["colors/surfaces/background/background_level0"],
  },
  CANCELED: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    textColor: colors["colors/system/error/error_normal"],
    text: "canceled",
  },
};

type CategoryTagProps = {
  status: string;
  isRowHover?: boolean;
};

type StatusArgs = {
  backgroundColor: string;
  textColor: string;
  text: string;
  colorHover?: string;
};

export const TableTagTransaction = (props: CategoryTagProps) => {
  const { t } = useTranslation();
  const statusTranslated = statusEnum.find(
    (s) => t(`transactions.status.${s}`) === props.status,
  );
  if (!statusTranslated) {
    return "";
  }

  const transactionStatus: StatusArgs = statusProps[statusTranslated as Status];

  return (
    <Tag
      text={props.status}
      backgroundColor={transactionStatus.backgroundColor}
      textColor={transactionStatus.textColor}
      colorHover={
        (props.isRowHover && transactionStatus.colorHover) || undefined
      }
    />
  );
};
