import {
  NotInterestedOutlined,
  Spacer,
  ButtonDS,
  ModalDS,
  TextCapitalized,
} from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateCardStatusAsync } from "./../cardsSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const SetCardInOppositionModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  cardUuid: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const setInOppositionCard = () => {
    void dispatch(
      updateCardStatusAsync({
        cardUuid: props.cardUuid,
        status: "IN_OPPOSITION",
      }),
    );
    props.onCloseModal();
  };

  return (
    <ModalDS
      visible={props.isVisible}
      onClose={() => {
        props.onCloseModal();
      }}
    >
      <StyledModalContainer>
        <StyledIcon>
          <NotInterestedOutlined />
        </StyledIcon>
        <Spacer y={2.5} />
        <TextCapitalized>
          <StyledModalTitle>
            {t("cards.IN_OPPOSITION.modal.title")}
          </StyledModalTitle>
          <Spacer y={1} />
        </TextCapitalized>
        <StyledModalContent>
          <TextCapitalized>
            {t("cards.IN_OPPOSITION.modal.content")}
          </TextCapitalized>
        </StyledModalContent>
        <Spacer y={1.5} />
        <ButtonDS
          text={t("cards.IN_OPPOSITION.modal.button.cancel")}
          onClick={() => {
            props.onCloseModal();
          }}
          buttonType={"secondary"}
          format={"fill"}
        />
        <Spacer y={0.5} />
        <ButtonDS
          text={t("cards.IN_OPPOSITION.modal.button.confirm")}
          onClick={() => setInOppositionCard()}
          buttonType={"danger"}
          format={"fill"}
        />
      </StyledModalContainer>
    </ModalDS>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const StyledModalTitle = styled.div`
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
`;

const StyledModalContent = styled.div`
  ${typographies["Body/S"]};
  display: flex;
  flex-direction: column;
  color: ${colors["colors/text/darkGrey"]};
  text-align: center;
`;

const StyledIcon = styled.div`
  & svg {
    width: 3.5em;
    height: 3.5em;
  }
  height: 4rem;
  width: 4rem;
  border: 0.032rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 0.5rem;
  fill: ${colors["colors/system/error/error_normal"]};
  justify-content: center;
  display: flex;
  align-items: center;
`;
