import { ButtonDS, TextCapitalized, DialogModalDS } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { updateDriverStatusAsync } from "./driversSlice";
import { selectCompany } from "../homeSlice";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const DriverDeleteModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  driverUuid: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);

  const setInOppositionDriver = () => {
    if (!company) return;
    void dispatch(
      updateDriverStatusAsync({
        driverUuid: props.driverUuid,
        companyUuid: company.uuid,
        status: "DELETE",
      }),
    );
    props.onCloseModal();
  };

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={() => {
        props.onCloseModal();
      }}
      title={t("drivers.deleteModal.title")}
      bottomButtons={{
        leftButton: (
          <ButtonDS
            text={t("drivers.deleteModal.button.cancel")}
            onClick={() => {
              props.onCloseModal();
            }}
            buttonType={"secondary"}
            format={"fill"}
          />
        ),
        rightButton: (
          <ButtonDS
            text={t("drivers.deleteModal.button.confirm")}
            onClick={() => setInOppositionDriver()}
            buttonType={"primary"}
            format={"fill"}
          />
        ),
      }}
    >
      <StyledContent>
        <TextCapitalized>{t("drivers.deleteModal.content")}</TextCapitalized>
      </StyledContent>
    </DialogModalDS>
  );
};

const StyledContent = styled.div`
  ${typographies["Body/S"]};
  color: ${colors["colors/text/lightGrey"]};
`;
