import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  ButtonDS,
  CritAir,
  EnergyClass,
  NotInterestedOutlined,
  RemoveRedEyeOutlined,
  SidePanelDS,
  Spacer,
  Tag,
  TextCapitalized,
  dateFormatterDayMonthYear,
} from "@qivia/ui";
import { useAppSelector } from "../../../redux/hooks";
import { selectListAllVehiclesInDb } from "./vehiclesSlice";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { DeactivateVehicleModal } from "./modals/deactivateVehicleModal";

export const VehicleSidePanel: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const listAllVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);
  const vehicle = listAllVehiclesInDb.find((v) => v.uuid === params.id);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] =
    useState(false);

  useEffect(() => {
    if (!vehicle) {
      navigate(`/home/vehicles`);
    }
  }, [navigate, vehicle]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/vehicles");
  }, [navigate]);

  if (!vehicle) {
    return;
  }

  return (
    <SidePanelDS.SidePanel
      visible={true}
      onClose={onSidePanelClosed}
      absoluteSection={
        <ButtonDS
          leftIcon={<NotInterestedOutlined />}
          text={t("vehicles.sidepanel.deactivate")}
          format={"fill"}
          buttonType={"secondary"}
          onClick={() =>
            vehicle.status !== "DEACTIVATED" &&
            setIsDeactivateModalVisible(!isDeactivateModalVisible)
          }
          iconColor={"errorNormal"}
          disabled={vehicle.status === "DEACTIVATED"}
        />
      }
    >
      <DeactivateVehicleModal
        isVisible={isDeactivateModalVisible}
        onCloseModal={() => {
          setIsDeactivateModalVisible(false);
        }}
        vehicleUuid={vehicle.uuid}
      />
      <SidePanelDS.Section
        verticalSpacing={2}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          <StyledContent>{vehicle.registrationNumber}</StyledContent>
          {vehicle.reference && (
            <>
              <Spacer y={0.5} />
              <Tag
                text={vehicle.reference}
                borderColor={colors["colors/borders/cells/cells"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                textColor={colors["colors/button/primary/default"]}
              />
            </>
          )}
          <StyledImg src={Car} alt="Car" />
          <Spacer y={1} />
          <StyledContent>
            {`${vehicle.brand ? vehicle.brand : ""} ${vehicle.model ? vehicle.model : ""}`.trim()}
          </StyledContent>
          {vehicle.energy && (
            <>
              <Spacer y={0.25} />
              <StyledEnergie>
                <TextCapitalized>
                  {vehicle.energy && t(`vehicles.energie.${vehicle.energy}`)}
                </TextCapitalized>
              </StyledEnergie>
            </>
          )}
        </StyledPanelContainer>
        <Spacer y={1.5} />
        {vehicle.cardUuid && vehicle.rule && (
          <SidePanelDS.ActionBlock>
            <>
              {vehicle.cardUuid && vehicle.cardName && (
                <SidePanelDS.SectionedActionStripe
                  left={
                    <StyledRow>
                      <LittleCardAsset />
                      <Spacer x={1} />
                      {`**** ${vehicle.cardName}`}
                    </StyledRow>
                  }
                  right={
                    <StyledLittleIcon>
                      <RemoveRedEyeOutlined
                        key="card"
                        onClick={() =>
                          navigate(`/home/cards/${vehicle.cardUuid}`)
                        }
                      />
                      <Spacer x={1} />
                    </StyledLittleIcon>
                  }
                />
              )}
              {vehicle.rule && (
                <SidePanelDS.SectionedActionStripe
                  left={
                    <StyledRowCenter>
                      <StyledBodyM>
                        <TextCapitalized>
                          {t(`cards.sidePanel.informations.title.rule`)}
                        </TextCapitalized>
                      </StyledBodyM>
                      <Spacer x={0.5} />
                      {vehicle.ruleName}
                    </StyledRowCenter>
                  }
                  right={
                    <StyledLittleIcon>
                      <RemoveRedEyeOutlined
                        key="rule"
                        onClick={() => navigate(`/home/rule/${vehicle.rule}`)}
                      />
                      <Spacer x={1} />
                    </StyledLittleIcon>
                  }
                />
              )}
            </>
          </SidePanelDS.ActionBlock>
        )}
      </SidePanelDS.Section>
      <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>{t("vehicles.sidepanel.details")}</TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.status")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <TextCapitalized>
              {t(`vehicles.status.${vehicle.status}`)}
            </TextCapitalized>
          }
        />
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>{t("vehicles.sidepanel.type")}</TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <Tag
              text={t(`vehicles.type.${vehicle.vehicleType}`)}
              borderColor={colors["colors/borders/cells/cells"]}
              backgroundColor={
                colors["colors/surfaces/background/background_level0"]
              }
              textColor={colors["colors/text/darkGrey"]}
            />
          }
        />
      </SidePanelDS.Section>
      <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.informations")}
          </TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.mileage")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {!vehicle.latestMileage ? "-" : vehicle.latestMileage}
              <Spacer x={0.25} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("vehicles.sidepanel.mileage.units")}
                </TextCapitalized>
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <Spacer y={0.25} />
        {vehicle.latestMileageDate && (
          <StyledSubTitle>
            <TextCapitalized>
              {t("vehicles.sidepanel.mileageDate")}
            </TextCapitalized>
            <Spacer x={0.25} />:<Spacer x={0.25} />
            {dateFormatterDayMonthYear(new Date(vehicle.latestMileageDate))}
          </StyledSubTitle>
        )}
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.consumption.title")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {!vehicle.consumption ? "-" : vehicle.consumption}
              <Spacer x={0.25} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("vehicles.sidepanel.consumption.units")}
                </TextCapitalized>
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <StyledSubTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.consumption.subTitle")}
          </TextCapitalized>
        </StyledSubTitle>
      </SidePanelDS.Section>

      <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>{t("vehicles.sidepanel.pollution")}</TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.emission")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.emission === null ? "-" : vehicle.emission}
              <Spacer x={0.25} />
              <StyledSubTitle>
                {t("vehicles.sidepanel.emission.units")}
              </StyledSubTitle>
            </StyledRow>
          }
        />
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.energyClass.title")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.energyClass === null ? (
                "-"
              ) : (
                <>
                  <EnergyClass
                    energyClass={vehicle.energyClass as EnergyClass}
                  />
                  <Spacer x={0.25} />
                </>
              )}
              <Spacer x={0.25} />
            </StyledRow>
          }
        />
        <Spacer y={0.25} />
        {vehicle.energyClass && (
          <StyledSubTitle>
            <TextCapitalized>
              {t(`vehicles.sidepanel.energyClass.${vehicle.energyClass}`)}
            </TextCapitalized>
            <Spacer x={0.25} />
          </StyledSubTitle>
        )}
        <Spacer y={2} />

        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.critAir")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {vehicle.critAir === null ? (
                <>
                  {"-"} <Spacer x={0.25} />
                </>
              ) : (
                <CritAir critAir={`${vehicle.critAir}`.toString() as CritAir} />
              )}
            </StyledRow>
          }
        />
      </SidePanelDS.Section>

      <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
        <SectionTitle>
          <TextCapitalized>
            {t("vehicles.sidepanel.driverHistory")}
          </TextCapitalized>
        </SectionTitle>
        <Spacer y={2} />
        <SidePanelDS.SectionRow
          label={
            <SectionRowLabel>
              <TextCapitalized>
                {t("vehicles.sidepanel.lastDriver")}
              </TextCapitalized>
            </SectionRowLabel>
          }
          value={
            <StyledRow>
              {!vehicle.lastDriver ? "-" : vehicle.lastDriver}
            </StyledRow>
          }
        />
        <Spacer y={2} />
      </SidePanelDS.Section>
    </SidePanelDS.SidePanel>
  );
};

const StyledSubTitle = styled.div`
  display: flex;
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SectionTitle = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/darkGrey"]}
`;

const SectionRowLabel = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]}
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledBodyM = styled.div`
  ${typographies["Body/M"]}
`;

const StyledContent = styled.div`
  ${typographies["Header/H4"]};
`;

const StyledImg = styled.img`
  filter: grayscale(100%);
  width: 15rem;
  height: 6.5rem;
  object-fit: contain;
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
const StyledLittleIcon = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledEnergie = styled.span`
  ${typographies["Body/S"]};
  color: ${colors["colors/text/darkGrey"]};
  text-align: center;
`;
