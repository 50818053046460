import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  BadgeCreation,
  BadgesList,
  BadgesListApi,
  ReplacementBadgeType,
  UpdateBadgeStatusType,
  UpdateStatusType,
} from "./badgesAPI";

export interface BadgesState {
  badgesListStatus: QueryStatus;
  badgesList: BadgesList[];
  badgesCreationStatus: QueryStatus;
  badgeUpdateStatus: QueryStatus;
  badgeNewStatus: UpdateStatusType | null;
  replacementBadgeStatus: QueryStatus;
  badgesOrderedNumber: number | null;
}

const initialState: BadgesState = {
  badgesListStatus: "idle",
  badgesList: [],
  badgesCreationStatus: "idle",
  badgeUpdateStatus: "idle",
  badgeNewStatus: null,
  replacementBadgeStatus: "idle",
  badgesOrderedNumber: null,
};

export const badgesListAsync = createAsyncThunk(
  "badgesList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<BadgesListApi>>(
      `badges/company/${companyUuid}`,
    );
    const badgesList = response.data.map((payload) => ({
      ...payload,
      date: payload.createdAt,
      status: payload.status === "RETURNED" ? "IN_OPPOSITION" : payload.status,
      reference: payload.reference?.toLocaleUpperCase(),
    }));
    return badgesList;
  },
);

export const createBadgesAsync = createAsyncThunk(
  "createBadges/call",
  async (payload: { companyUuid: string; request: BadgeCreation }) => {
    const axios = authAxios();
    await axios.post(`badges/company/${payload.companyUuid}`, payload.request);
    return payload.request.vehicles.length;
  },
);

export const updateBadgeStatusAsync = createAsyncThunk(
  "updateBadgeStatus/call",
  async (payload: UpdateBadgeStatusType) => {
    const axios = authAxios();
    await axios.post(`/badge/${payload.badgeUuid}/update_status`, {
      status: payload.status,
      companyUuid: payload.companyUuid,
    });
    return payload.status;
  },
);

export const replacementBadgeAsync = createAsyncThunk(
  "replacementBadge/call",
  async (payload: ReplacementBadgeType) => {
    const axios = authAxios();
    await axios.post(`/badge/${payload.badgeUuid}/defective_replacement`, {
      companyUuid: payload.companyUuid,
    });
  },
);

export const badgesSlice = createSlice({
  name: "badges",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetBadgesListStatus(state) {
      state.badgesListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(badgesListAsync.pending, (state) => {
        state.badgesListStatus = "processing";
      })
      .addCase(badgesListAsync.fulfilled, (state, action) => {
        state.badgesList = action.payload;
        state.badgesCreationStatus = "idle";
        state.badgeUpdateStatus = "idle";
        state.replacementBadgeStatus = "idle";
        state.badgeNewStatus = null;
        state.badgesListStatus = "success";
      })
      .addCase(badgesListAsync.rejected, (state) => {
        state.badgesListStatus = "failed";
      })
      .addCase(createBadgesAsync.pending, (state) => {
        state.badgesCreationStatus = "processing";
      })
      .addCase(createBadgesAsync.fulfilled, (state, action) => {
        state.badgesCreationStatus = "success";
        state.badgesOrderedNumber = action.payload;
      })
      .addCase(createBadgesAsync.rejected, (state) => {
        state.badgesCreationStatus = "failed";
      })
      .addCase(updateBadgeStatusAsync.pending, (state) => {
        state.badgeUpdateStatus = "processing";
      })
      .addCase(updateBadgeStatusAsync.fulfilled, (state, action) => {
        state.badgeUpdateStatus = "success";
        state.badgeNewStatus = action.payload;
      })
      .addCase(updateBadgeStatusAsync.rejected, (state) => {
        state.badgeUpdateStatus = "failed";
      })
      .addCase(replacementBadgeAsync.pending, (state) => {
        state.replacementBadgeStatus = "processing";
      })
      .addCase(replacementBadgeAsync.fulfilled, (state) => {
        state.replacementBadgeStatus = "success";
      })
      .addCase(replacementBadgeAsync.rejected, (state) => {
        state.replacementBadgeStatus = "failed";
      });
  },
});

export const selectBadgesList = (state: RootState) => state.badges.badgesList;

export const selectBadgesListStatus = (state: RootState) =>
  state.badges.badgesListStatus;

export const selectBadgesCreationStatus = (state: RootState) =>
  state.badges.badgesCreationStatus;

export const selectBadgeUpdateStatus = (state: RootState) =>
  state.badges.badgeUpdateStatus;

export const selectNewBadgeStatus = (state: RootState) =>
  state.badges.badgeNewStatus;

export const selectReplacementBadgeStatus = (state: RootState) =>
  state.badges.replacementBadgeStatus;

export const selectBadgesOrderedNumber = (state: RootState) =>
  state.badges.badgesOrderedNumber;

export default badgesSlice.reducer;
