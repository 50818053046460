/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as XLSX from "xlsx";
import { hasRegistrationNumberFormat } from "./format";

export const isValidExcelColumnsNamingForMultipleVehicles = (
  workSheet: XLSX.WorkSheet,
) => {
  const areMandatoryHeaderFilled =
    workSheet.A1?.h && workSheet.B1?.h && workSheet.C1?.h;

  const areNoOtherHearders =
    !workSheet.D1?.h &&
    !workSheet.E1?.h &&
    !workSheet.F1?.h &&
    !workSheet.G1?.h;

  const areOptionalHeaderFilled =
    workSheet.D1?.h && workSheet.E1?.h && workSheet.F1?.h && workSheet.G1?.h;

  const areMandatoryHeadersValid =
    workSheet.A1?.h === "Immatriculation" &&
    workSheet.B1?.h === "Référence" &&
    workSheet.C1?.h === "Type de véhicule";

  const areOptionalHeadersValid =
    workSheet.D1?.h === "Surcoût au km" &&
    workSheet.E1?.h === "Date début de contrat" &&
    workSheet.F1?.h === "Date fin de contrat" &&
    workSheet.G1?.h === "Kilométrage contractuel";

  if (areMandatoryHeaderFilled && areNoOtherHearders) {
    return areMandatoryHeadersValid;
  } else if (areMandatoryHeaderFilled && areOptionalHeaderFilled) {
    return areMandatoryHeadersValid && areOptionalHeadersValid;
  }
  return false;
};

export const cellsContentErrorsForMultipleVehicles = (
  workSheet: XLSX.WorkSheet,
  fileSize: number,
) => {
  const cellsErrors: Array<{
    cell: string;
    content: string;
    errorType: string;
  }> = [];

  if (!isValidExcelColumnsNamingForMultipleVehicles(workSheet)) {
    if (workSheet.A1?.h !== "Immatriculation") {
      cellsErrors.push({
        cell: "A1",
        content: workSheet.A1?.h as string,
        errorType: "header",
      });
    }
    if (workSheet.B1?.h !== "Référence") {
      cellsErrors.push({
        cell: "B1",
        content: workSheet.B1?.h as string,
        errorType: "header",
      });
    }
    if (workSheet.C1?.h !== "Type de véhicule") {
      cellsErrors.push({
        cell: "C1",
        content: workSheet.C1?.h as string,
        errorType: "header",
      });
    }
    if (
      (workSheet.D1?.h && workSheet.D1?.h !== "Surcoût au km") ||
      (!workSheet.D1?.h &&
        (workSheet.E1?.h || workSheet.F1?.h || workSheet.G1?.h))
    ) {
      cellsErrors.push({
        cell: "D1",
        content: workSheet.D1?.h as string,
        errorType: "header",
      });
    }
    if (
      (workSheet.E1?.h && workSheet.E1?.h !== "Date début de contrat") ||
      (!workSheet.E1?.h &&
        (workSheet.D1?.h || workSheet.F1?.h || workSheet.G1?.h))
    ) {
      cellsErrors.push({
        cell: "E1",
        content: workSheet.E1?.h as string,
        errorType: "header",
      });
    }
    if (
      (workSheet.F1?.h && workSheet.F1?.h !== "Date fin de contrat") ||
      (!workSheet.F1?.h &&
        (workSheet.E1?.h || workSheet.D1?.h || workSheet.G1?.h))
    ) {
      cellsErrors.push({
        cell: "F1",
        content: workSheet.F1?.h as string,
        errorType: "header",
      });
    }
    if (
      (workSheet.G1?.h && workSheet.G1?.h !== "Kilométrage contractuel") ||
      (!workSheet.G1?.h &&
        (workSheet.E1?.h || workSheet.D1?.h || workSheet.F1?.h))
    ) {
      cellsErrors.push({
        cell: "G1",
        content: workSheet.G1?.h as string,
        errorType: "header",
      });
    }
  }
  const registrationNumbers: string[] = [];
  const references: string[] = [];
  for (let i = 2; i <= fileSize + 1; i++) {
    const cellValueA = workSheet[`A${i}`]?.v as string;
    if (cellValueA && !hasRegistrationNumberFormat(cellValueA)) {
      cellsErrors.push({
        cell: `A${i}`,
        content: cellValueA,
        errorType: "registrationNumber",
      });
    } else {
      registrationNumbers.push(cellValueA);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const cellValueB = workSheet[`B${i}`]?.v as string;
    if (cellValueB) {
      if (cellValueB.length > 28) {
        cellsErrors.push({
          cell: `B${i}`,
          content: cellValueB,
          errorType: "reference",
        });
      } else if (hasRegistrationNumberFormat(cellValueB)) {
        cellsErrors.push({
          cell: `B${i}`,
          content: cellValueB,
          errorType: "reference.registrationNumberFormat",
        });
      } else {
        references.push(cellValueB);
      }
    }
    if (!cellValueA && !cellValueB) {
      cellsErrors.push({
        cell: `A${i}`,
        content: cellValueB,
        errorType: "emptyName",
      });
    }
    const cellValueC = workSheet[`C${i}`]?.v as string;
    if (cellValueC) {
      if (
        cellValueC.toUpperCase() !== "MONO-CONDUCTEUR" &&
        cellValueC.toUpperCase() !== "MULTI-CONDUCTEURS"
      ) {
        cellsErrors.push({
          cell: `C${i}`,
          content: cellValueC,
          errorType: "vehicleType",
        });
      }
    } else {
      cellsErrors.push({
        cell: `C${i}`,
        content: cellValueC,
        errorType: "emptyCell",
      });
    }
    const cellValueD = workSheet[`D${i}`]?.v as number;
    if (cellValueD) {
      if (typeof cellValueD !== "number") {
        cellsErrors.push({
          cell: `D${i}`,
          content: cellValueD,
          errorType: "typeOfAdditionalCost",
        });
      }
    }
    const cellValueE = workSheet[`E${i}`]?.v as number;
    if (cellValueE) {
      if (typeof cellValueE !== "number" || cellValueE < 25569) {
        cellsErrors.push({
          cell: `E${i}`,
          content: cellValueE.toString(),
          errorType: "typeOfDate",
        });
      }
    }
    const cellValueF = workSheet[`F${i}`]?.v as number;
    if (cellValueF) {
      if (typeof cellValueF !== "number" || cellValueF < 25569) {
        cellsErrors.push({
          cell: `F${i}`,
          content: cellValueF.toString(),
          errorType: "typeOfDate",
        });
      }
    }
    const cellValueG = workSheet[`G${i}`]?.v as number;
    if (cellValueG) {
      if (typeof cellValueG !== "number") {
        cellsErrors.push({
          cell: `G${i}`,
          content: cellValueG,
          errorType: "contratMileage",
        });
      }
    }
  }
  const duplicateRegistrationNumbers = registrationNumbers.filter(
    (item, index) => registrationNumbers.indexOf(item) !== index && item,
  );
  if (duplicateRegistrationNumbers.length > 0) {
    cellsErrors.push({
      cell: `A`,
      content: [...new Set(duplicateRegistrationNumbers)].join(","),
      errorType: "duplicate.registrationNumbers",
    });
  }
  const duplicateReferences = references.filter(
    (item, index) => references.indexOf(item) !== index && item,
  );
  if (duplicateReferences.length > 0) {
    cellsErrors.push({
      cell: `B`,
      content: [...new Set(duplicateReferences)].join(","),
      errorType: "duplicate.references",
    });
  }

  return cellsErrors;
};
