import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import { AnalyticsVehicles, AnalyticsVehiclesApi } from "./fleetApi";
import {
  getAdditionalCostEndContract,
  getConsumedContractDurationMonth,
  getContractDurationMonth,
  getContractMensualMileage,
  getMensualMileage,
  getMileageDeviationPercentage,
  getMileageVariationEndContract,
  getMonthlyMileageDeviation,
  getProjectionEndOfContract,
  getRemainingContractDurationMonth,
} from "./lib";
import { capitalize } from "@qivia/ui";

export interface FleetState {
  analyticVehiclesStatus: QueryStatus;
  analyticVehicles: AnalyticsVehicles[];
}

const initialState: FleetState = {
  analyticVehiclesStatus: "idle",
  analyticVehicles: [],
};

export const analyticVehiclesAsync = createAsyncThunk(
  "analyticVehicles/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<AnalyticsVehiclesApi[]>(
      `vehicles_analytics/company/${companyUuid}`,
    );

    return response.data.map((d) => {
      const additionalCostPerKm =
        d.additionalCostPerKm &&
        Number((d.additionalCostPerKm / 100).toFixed(2));

      //DATE CALCULATION
      const contractDurationMonth = getContractDurationMonth(
        d.contractStartDate,
        d.contractEndDate,
      );

      const consumedContractDuration = getConsumedContractDurationMonth(
        d.contractStartDate,
        contractDurationMonth,
      );

      const remainingContractDuration = getRemainingContractDurationMonth(
        contractDurationMonth,
        consumedContractDuration,
      );

      //MILEAGE CALCULATION
      const mensualContractMileage = getContractMensualMileage(
        d.contractMileage,
        contractDurationMonth,
      );

      const mensualMileage = getMensualMileage(d.mileage, d.contractStartDate);

      const projectionEndOfContract = getProjectionEndOfContract(
        mensualMileage,
        contractDurationMonth,
      );
      const mileageVariationEndContract = getMileageVariationEndContract(
        d.contractMileage,
        projectionEndOfContract,
      );
      const additionalCostEndContract = getAdditionalCostEndContract(
        mileageVariationEndContract,
        additionalCostPerKm,
      );

      const monthlyMileageKmVariation = getMonthlyMileageDeviation(
        mensualMileage,
        mensualContractMileage,
      );
      const mileageDeviationPercentage = getMileageDeviationPercentage(
        mensualMileage,
        mensualContractMileage,
      );
      return {
        ...d,
        contractDurationMonth,
        mensualContractMileage,
        mensualMileage,
        mileageDeviation:
          mileageDeviationPercentage && mileageDeviationPercentage > 10
            ? ("OVERROLLING" as const)
            : mileageDeviationPercentage && mileageDeviationPercentage < -10
              ? ("UNDERROLLING" as const)
              : mileageDeviationPercentage
                ? ("CORRECT" as const)
                : null,
        monthlyMileageKmVariation,
        mileageDeviationPercentage,
        projectionEndOfContract,
        mileageVariationEndContract,
        additionalCostEndContract,
        additionalCostPerKm,
        consumedContractDuration,
        remainingContractDuration,
        contractStartDate: d.contractStartDate,
        contractEndDate: d.contractEndDate,
        driverName: d.driverName && capitalize(d.driverName),
        reference: d.reference?.toLocaleUpperCase() ?? null,
      };
    });
  },
);

export const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetFleetListStatus(state) {
      state.analyticVehiclesStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(analyticVehiclesAsync.pending, (state) => {
        state.analyticVehiclesStatus = "processing";
      })
      .addCase(analyticVehiclesAsync.fulfilled, (state, action) => {
        state.analyticVehicles = action.payload;
        state.analyticVehiclesStatus = "success";
      })
      .addCase(analyticVehiclesAsync.rejected, (state) => {
        state.analyticVehiclesStatus = "failed";
      });
  },
});

export const selectAnalyticsVehiclesList = (state: RootState) =>
  state.fleet.analyticVehicles;
export const selectAnalyticsVehiclesListStatus = (state: RootState) =>
  state.fleet.analyticVehiclesStatus;

export default fleetSlice.reducer;
