import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  LastCreatedPasswordRequest,
  ManagerCreation,
  ManagerDataAPI,
  ManagerDataDisplayRow,
  UsersDatasAPI,
  UsersPasswordsAPI,
} from "./usersAPI";

export interface UsersState {
  updateUserStatus: QueryStatus;
  updateUserPasswordStatus: QueryStatus;
  managersListStatus: QueryStatus;
  managersList: ManagerDataDisplayRow[];
  userCreationStatus: QueryStatus;
  deactivateManagerStatus: QueryStatus;
  relaunchPasswordCreationRequestStatus: QueryStatus;
  cancelPasswordCreationRequestStatus: QueryStatus;
  lastCreatedPasswordRequest: LastCreatedPasswordRequest;
  lastCreatedPasswordRequestStatus: QueryStatus;
}

const initialState: UsersState = {
  updateUserStatus: "idle",
  updateUserPasswordStatus: "idle",
  managersListStatus: "idle",
  managersList: [],
  userCreationStatus: "idle",
  deactivateManagerStatus: "idle",
  relaunchPasswordCreationRequestStatus: "idle",
  cancelPasswordCreationRequestStatus: "idle",
  lastCreatedPasswordRequest: { lastCreatedPasswordRequest: null },
  lastCreatedPasswordRequestStatus: "idle",
};

export const userAsync = createAsyncThunk(
  "userCreation/call",
  async (payload: ManagerCreation) => {
    const axios = authAxios();
    await axios.post("/pending_manager", payload);
  },
);

export const updateUsersAsync = createAsyncThunk(
  "Users/call",
  async (payload: UsersDatasAPI) => {
    const axios = authAxios();
    const response = await axios.post<{ token: string }>("/users", payload);
    return response.data.token;
  },
);

export const updateUsersPasswordAsync = createAsyncThunk(
  "UsersPassword/call",
  async (payload: UsersPasswordsAPI) => {
    const axios = authAxios();
    const response = await axios.post<{ token: string }>(
      "/users/password",
      payload,
    );
    return response.data.token;
  },
);

export const managersListAsync = createAsyncThunk(
  "managersList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<ManagerDataAPI[]>("/managers");
    return response.data.map((d) => {
      return {
        ...d,
        name: d.firstName + " " + d.lastName,
      };
    });
  },
);

export const deactivateManagerAsync = createAsyncThunk(
  "deactivateManagerAsync/call",
  async (payload: { managerUuid: string }) => {
    const axios = authAxios();
    await axios.post(`/manager/${payload.managerUuid}/deactivate`);
  },
);

export const relaunchPasswordCreationRequestAsync = createAsyncThunk(
  "relaunchPasswordCreationRequestAsync/call",
  async (payload: { managerUuid: string }) => {
    const axios = authAxios();
    await axios.post(
      `/manager/${payload.managerUuid}/relaunch_password_creation_request`,
    );
  },
);

export const cancelPasswordCreationRequestAsync = createAsyncThunk(
  "cancelPasswordCreationRequestAsync/call",
  async (payload: { managerUuid: string }) => {
    const axios = authAxios();
    await axios.post(
      `/manager/${payload.managerUuid}/cancel_password_creation_request`,
    );
  },
);

export const lastCreatedPasswordRequestAsync = createAsyncThunk(
  "lastCreatedPasswordRequestAsync/call",
  async (payload: { managerUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<LastCreatedPasswordRequest>(
      `/manager/${payload.managerUuid}/last_created_password_request`,
    );
    return response.data;
  },
);

export const UsersSlice = createSlice({
  name: "Users",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUpdateUserStatus(state) {
      state.updateUserStatus = "idle";
    },
    resetUpdatePasswordStatus(state) {
      state.updateUserPasswordStatus = "idle";
    },
    resetDeactivatedManagerStatus(state) {
      state.deactivateManagerStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updateUsersAsync.pending, (state) => {
        state.updateUserStatus = "processing";
      })
      .addCase(updateUsersAsync.fulfilled, (state) => {
        state.updateUserStatus = "success";
      })
      .addCase(updateUsersAsync.rejected, (state) => {
        state.updateUserStatus = "failed";
      })
      .addCase(updateUsersPasswordAsync.pending, (state) => {
        state.updateUserPasswordStatus = "processing";
      })
      .addCase(updateUsersPasswordAsync.fulfilled, (state) => {
        state.updateUserPasswordStatus = "success";
      })
      .addCase(updateUsersPasswordAsync.rejected, (state) => {
        state.updateUserPasswordStatus = "failed";
      })
      .addCase(managersListAsync.pending, (state) => {
        state.managersListStatus = "processing";
      })
      .addCase(managersListAsync.fulfilled, (state, action) => {
        state.managersListStatus = "success";
        state.managersList = action.payload;
      })
      .addCase(managersListAsync.rejected, (state) => {
        state.managersListStatus = "failed";
      })
      .addCase(userAsync.pending, (state) => {
        state.userCreationStatus = "processing";
      })
      .addCase(userAsync.fulfilled, (state) => {
        state.userCreationStatus = "success";
        state.managersListStatus = "idle";
      })
      .addCase(userAsync.rejected, (state) => {
        state.userCreationStatus = "failed";
      })
      .addCase(deactivateManagerAsync.pending, (state) => {
        state.deactivateManagerStatus = "processing";
      })
      .addCase(deactivateManagerAsync.fulfilled, (state) => {
        state.deactivateManagerStatus = "success";
        state.managersListStatus = "idle";
      })
      .addCase(deactivateManagerAsync.rejected, (state) => {
        state.deactivateManagerStatus = "failed";
      })
      .addCase(relaunchPasswordCreationRequestAsync.pending, (state) => {
        state.relaunchPasswordCreationRequestStatus = "processing";
      })
      .addCase(relaunchPasswordCreationRequestAsync.fulfilled, (state) => {
        state.relaunchPasswordCreationRequestStatus = "success";
        state.managersListStatus = "idle";
        state.lastCreatedPasswordRequestStatus = "idle";
      })
      .addCase(relaunchPasswordCreationRequestAsync.rejected, (state) => {
        state.relaunchPasswordCreationRequestStatus = "failed";
      })
      .addCase(cancelPasswordCreationRequestAsync.pending, (state) => {
        state.cancelPasswordCreationRequestStatus = "processing";
      })
      .addCase(cancelPasswordCreationRequestAsync.fulfilled, (state) => {
        state.cancelPasswordCreationRequestStatus = "success";
        state.managersListStatus = "idle";
        state.lastCreatedPasswordRequestStatus = "idle";
      })
      .addCase(cancelPasswordCreationRequestAsync.rejected, (state) => {
        state.cancelPasswordCreationRequestStatus = "failed";
      })
      .addCase(lastCreatedPasswordRequestAsync.pending, (state) => {
        state.lastCreatedPasswordRequestStatus = "processing";
      })
      .addCase(lastCreatedPasswordRequestAsync.fulfilled, (state, action) => {
        state.lastCreatedPasswordRequestStatus = "success";
        state.lastCreatedPasswordRequest = action.payload;
      })
      .addCase(lastCreatedPasswordRequestAsync.rejected, (state) => {
        state.lastCreatedPasswordRequestStatus = "failed";
      });
  },
});

export const selectUsersStatus = (state: RootState) =>
  state.users.updateUserStatus;
export const selectUsersPasswordStatus = (state: RootState) =>
  state.users.updateUserPasswordStatus;
export const selectManagersList = (state: RootState) =>
  state.users.managersList;
export const selectManagersListStatus = (state: RootState) =>
  state.users.managersListStatus;
export const selectManagerCreationStatus = (state: RootState) =>
  state.users.userCreationStatus;
export const selectDeactivateManagerStatus = (state: RootState) =>
  state.users.deactivateManagerStatus;
export const selectRelaunchPasswordCreationRequestStatus = (state: RootState) =>
  state.users.relaunchPasswordCreationRequestStatus;
export const selectCancelPasswordCreationRequestStatus = (state: RootState) =>
  state.users.cancelPasswordCreationRequestStatus;
export const selectLastCreatedPasswordRequestStatus = (state: RootState) =>
  state.users.lastCreatedPasswordRequestStatus;
export const selectLastCreatedPasswordRequest = (state: RootState) =>
  state.users.lastCreatedPasswordRequest;

export default UsersSlice.reducer;
