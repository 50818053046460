import {
  TextCapitalized,
  Spacer,
  InputDS,
  ButtonDS,
  hasIbanFormat,
} from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PublicCompany } from "../../homeSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { createExternalBankAccountAsync } from "../sepaDebitSlice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export const ExternalBankAccountModal = (props: {
  company: PublicCompany;
  isVisible: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [ibanInput, setIbanInput] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const navigate = useNavigate();

  const isValidForm: boolean = useMemo(
    () => hasIbanFormat(ibanInput) && ibanInput !== "",
    [ibanInput],
  );

  const getIbanError = useCallback(() => {
    if (!displayError) return undefined;
    if (ibanInput === "") {
      return t("account.externalBankAccount.modal.iban.error.empty");
    } else if (!hasIbanFormat(ibanInput)) {
      return t("account.externalBankAccount.modal.iban.error.valid");
    } else return undefined;
  }, [displayError, ibanInput, t]);

  const onSubmit = useCallback(
    (iban: string) => {
      if (props.company.uuid) {
        void dispatch(
          createExternalBankAccountAsync({
            companyUuid: props.company.uuid,
            externalIban: iban,
          }),
        );
        navigate(`/home/billings/account`);
      }
    },
    [dispatch, navigate, props.company.uuid],
  );

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidForm && onSubmit(ibanInput);
  }, [ibanInput, isValidForm, onSubmit]);

  useEffect(() => {
    if (!props.isVisible && params.sepa_debit === "external_account") {
      navigate(`/home/billings/account`);
    }
  }, [navigate, params.sepa_debit, props.isVisible]);

  if (!props.isVisible) {
    return;
  }

  return (
    <StyledContainer>
      <StyledModal>
        <Spacer y={2} />
        <StyledExternal>
          <Spacer x={1} />
          <StyledColumn>
            <Spacer y={1} />
            <TextCapitalized>
              {t("account.externalBankAccount.modal.externalBankAccount")}
            </TextCapitalized>
            <Spacer y={2} />
            <StyledDescription>
              <TextCapitalized>
                {t(
                  "account.externalBankAccount.modal.externalBankAccount.description",
                )}
              </TextCapitalized>
            </StyledDescription>
            <Spacer y={2} />
          </StyledColumn>
          <Spacer x={1} />
        </StyledExternal>
        <Spacer y={1} />
        <TextCapitalized>
          {t("account.externalBankAccount.modal.bankName")}
        </TextCapitalized>
        <Spacer y={0.25} />
        <InputDS
          value={props.company.name}
          withoutSpacerBottom
          isDisabled={true}
        />
        <Spacer y={1} />
        <TextCapitalized>
          {t("account.externalBankAccount.modal.ibanButton")}
        </TextCapitalized>
        <Spacer y={0.25} />
        <InputDS
          placeholder={t("account.externalBankAccount.modal.placeholder")}
          error={getIbanError()}
          value={ibanInput}
          onChange={(e) => setIbanInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          withoutSpacerBottom
          isPlaceholderUppercase
        />
        <Spacer y={2} />
        <ButtonDS
          sizeButton={"S"}
          format={"hug"}
          buttonType={"primary"}
          text={t("account.externalBankAccount.modal.confirmButton")}
          onClick={() => clickToSubmit()}
        />
      </StyledModal>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
`;

const StyledModal = styled.div`
  width: 30.5rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledExternal = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledDescription = styled.div`
  ${typographies["Body/XXS"]};
`;
