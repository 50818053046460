import {
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  dateFormatterDayMonthYearLong2Digits,
} from "@qivia/ui";

export const formatCurrency = (value: number) =>
  hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(value);
export const formatPercent = (value: number) =>
  hasBadNumberValue(value / 100)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "percent",
      }).format(value / 100);
export const formatMileage = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "kilometer",
      }).format(value);
};
export const formatLiterPerOneHundredMileage = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 2,
      }).format(value) + " l/100 km";
};
export const formatCarbonEmissionPerKilometer = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "gram-per-kilometer",
      }).format(value);
};
export const formatMonth = (value: number) =>
  hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "month",
        unitDisplay: "long",
      }).format(value);

export const hasBadNumberValue = (value: number) => {
  return !value || isNaN(value);
};

export const formatDate = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? dateFormatterDayMonthYearLong2Digits(value)
    : "";

export const formatDateTime = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? dateFormatterDayMonthLongYearAndHourMinute2Digits(value)
    : "";
