import {
  sortByDateTime,
  Spacer,
  LaunchPage,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  Tag,
  MaterialUiTable,
  usePersistMuiTableState,
  MaterialUiColumnsProps,
} from "@qivia/ui";
import { Trans, useTranslation } from "react-i18next";
import {
  PublicBadgeTransactionDisplayed,
  PublicBadgeTransaction,
} from "./transactionsAPI";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  asfTransactionsListAsync,
  selectAsfTransactionsList,
  selectAsfTransactionsListStatus,
} from "./transactionsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCategoryName } from "./../rules/libDefaultDatas";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { TransactionsSidePanel } from "./SidePanel";
import { useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { AsfLogo } from "@qivia/ui/src/assets/assets";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { formatCurrency } from "../../../libSlice/materialUiFormats";

export const BadgeTransactionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const [listDisplayed, setListDisplayed] = useState<
    PublicBadgeTransactionDisplayed[] | null
  >(null);
  const transactionsListInit: PublicBadgeTransaction[] | null = useAppSelector(
    selectAsfTransactionsList,
  );
  const transactionsListStatus = useAppSelector(
    selectAsfTransactionsListStatus,
  );
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);

  useEffect(() => {
    if (company) {
      void dispatch(asfTransactionsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  const pageName = "asfTransactions";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      badgeNumber: "size",
      category: "size",
      amount: "avg",
      vehicleInfo: "size",
      stationsInfo: "size",
    },
  };

  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const keysToTranslate = useMemo(
    () => ["category", "date"] as (keyof PublicBadgeTransactionDisplayed)[],
    [],
  );

  const findKeysToTranslate = useCallback(
    (key: keyof PublicBadgeTransaction, value: string) => {
      switch (key) {
        case "category":
          return t(`transactions.category.${value}`);
        case "date":
          return dateFormatterDayMonthLongYearAndHourMinute2Digits(
            new Date(value),
          );
        default:
          return value;
      }
    },
    [t],
  );

  const allTransactionsDisplayed: PublicBadgeTransaction[] = useMemo(() => {
    if (!transactionsListInit) return [];
    const rowsWithId = transactionsListInit.map((av) => {
      let newDict: PublicBadgeTransaction & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (
          keysToTranslate.includes(key as keyof PublicBadgeTransactionDisplayed)
        ) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(
                  key as keyof PublicBadgeTransaction,
                  value as string,
                )
              : "",
          };
        }
      });
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc");
  }, [findKeysToTranslate, keysToTranslate, transactionsListInit]);

  useEffect(() => {
    if (allTransactionsDisplayed && transactionsListStatus === "success") {
      setListDisplayed(allTransactionsDisplayed);
    }
  }, [allTransactionsDisplayed, transactionsListStatus]);

  const searchBarProps = {
    values: allTransactionsDisplayed,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["type" as const, "uuid" as const],
  };

  const columnsDisplayed: {
    [index in keyof PublicBadgeTransactionDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      type: {
        type: "string",
        width: 60,
        valueFormatter: () => {
          return (
            <StyledRow>
              <Spacer x={0.5} />
              <AsfLogo />
            </StyledRow>
          );
        },
      },
      date: {
        type: "date",
        width: 210,
        valueFormatter: (value) => {
          return value;
        },
      },
      stationsInfo: {
        type: "number",
        width: 320,
        valueFormatter: (value: string) =>
          value && (
            <StyledStations>
              <Trans>{value}</Trans>
            </StyledStations>
          ),
      },
      category: {
        type: "string",
        valueFormatter: (value, row) => {
          const merchantCategory = getCategoryName(value);
          return (
            value && (
              <StyledRow>
                <Tag
                  text={merchantCategory}
                  backgroundColor={
                    colors["colors/surfaces/background/background_level2"]
                  }
                  textColor={colors["colors/text/black"]}
                  colorHover={
                    rowHoverUuid === row["uuid"]
                      ? colors["colors/surfaces/background/background_level0"]
                      : undefined
                  }
                />
              </StyledRow>
            )
          );
        },
      },
      badgeNumber: {
        type: "string",
        valueFormatter: (value: string) =>
          value && (
            <StyledRow>
              <Tag
                text={value}
                textColor={colors["colors/text/black"]}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledRow>
          ),
      },
      vehicleInfo: {
        type: "string",
        width: 300,
      },
      amount: {
        type: "number",
        valueFormatter: (value: number, row) =>
          value && (
            <StyledAmount $isCanceled={row.type === "REFUND"}>
              {formatCurrency(value)}
            </StyledAmount>
          ),
      },
    };
  }, [rowHoverUuid]);

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("asfTransactions.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "badges") {
    return;
  }
  return (
    <>
      <MaterialUiTable<
        keyof PublicBadgeTransactionDisplayed,
        PublicBadgeTransactionDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        setRowHoverUuid={setRowHoverUuid}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
        downloadViewAction={downloadViewAction}
      />
      <TransactionsSidePanel />
    </>
  );
};

const StyledStations = styled.div`
  ${typographies["Body/XS"]}
  display: flex;
  flex-flow: column wrap;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledAmount = styled.div<{
  $isCanceled?: boolean;
}>`
  text-decoration: ${({ $isCanceled }) =>
    $isCanceled ? "line-through" : "none"};
  ${typographies["Body/L"]}
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
