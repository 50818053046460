import * as XLSX from "xlsx";
import { Buffer } from "buffer";

export const createFileXlsx = (payload: {
  tabTitle: string;
  headerTitles: string[];
  body: (string | number)[][];
}) => {
  const workbook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, [payload.headerTitles], {
    cellStyles: true,
  });
  XLSX.utils.sheet_add_json(worksheet, payload.body, {
    origin: "A2",
    skipHeader: true,
    cellStyles: true,
  });
  XLSX.utils.book_append_sheet(workbook, worksheet, payload.tabTitle);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const buffer: Buffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  return buffer;
};
