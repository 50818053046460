import {
  sortByDateTime,
  Avatar,
  triggerToast,
  Spacer,
  Tag,
  LaunchPage,
  TableTagTransaction,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  MaterialUiTable,
  usePersistMuiTableState,
  MaterialUiColumnsProps,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import {
  PublicTransactionsPropsType,
  PublicTransactionDisplayed,
  TransactionStatus,
} from "./transactionsAPI";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  authorizationsListAsync,
  selectAuthorizationsList,
  selectAuthorizationsListStatus,
  selectTransactionsList,
  selectTransactionsListStatus,
  transactionsListAsync,
  selectSendSmsStatus,
  selectSendSmsReplaceInvalidStatus,
} from "./transactionsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCategoryName } from "./../rules/libDefaultDatas";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { TransactionsSidePanel } from "./SidePanel";
import { useNavigate, useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  formatCurrency,
  formatDateTime,
  formatMileage,
} from "../../../libSlice/materialUiFormats";
import { getDateTime } from "../fleet/lib";

export const CardTransactionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const [listDisplayed, setListDisplayed] = useState<
    PublicTransactionDisplayed[] | null
  >(null);
  const transactionsList: PublicTransactionsPropsType[] | null = useAppSelector(
    selectTransactionsList,
  );
  const authorizationsRefusedList: PublicTransactionsPropsType[] | null =
    useAppSelector(selectAuthorizationsList);
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);
  const transactionsListStatus = useAppSelector(selectTransactionsListStatus);
  const authorizationsListStatus = useAppSelector(
    selectAuthorizationsListStatus,
  );
  const sendSmsStatus = useAppSelector(selectSendSmsStatus);
  const sendSmsReplaceInvalidStatus = useAppSelector(
    selectSendSmsReplaceInvalidStatus,
  );
  const pageName = "transactions";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      amount: "avg",
      merchantName: "size",
      category: "size",
      driverName: "size",
      status: "size",
      supportingDocumentStatus: "size",
      mileage: "avg",
    },
  };

  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const keysToTranslate = useMemo(
    () =>
      [
        "status",
        "category",
        "date",
        "supportingDocumentStatus",
      ] as (keyof PublicTransactionDisplayed)[],
    [],
  );

  const findKeysToTranslate = useCallback(
    (key: keyof PublicTransactionsPropsType, value: string) => {
      switch (key) {
        case "status":
          return t(`transactions.status.${value}`);
        case "category":
          return t(`transactions.category.${value}`);
        case "date":
          return dateFormatterDayMonthLongYearAndHourMinute2Digits(
            new Date(value),
          );
        case "supportingDocumentStatus":
          return t(`transactions.supportingDocument.${value}`);
        default:
          return value;
      }
    },
    [t],
  );

  const allTransactionsDisplayed: PublicTransactionsPropsType[] =
    useMemo(() => {
      if (!transactionsList || !authorizationsRefusedList) return [];
      const list = [...transactionsList, ...authorizationsRefusedList];

      const rowsWithId = list.map((av) => {
        let newDict: PublicTransactionsPropsType & { id: string } = {
          ...av,
          id: av.uuid,
        };
        Object.entries(av).forEach(([key, value]) => {
          if (
            keysToTranslate.includes(key as keyof PublicTransactionDisplayed)
          ) {
            newDict = {
              ...newDict,
              [key]: value
                ? findKeysToTranslate(
                    key as keyof PublicTransactionsPropsType,
                    value as string,
                  )
                : "",
            };
          }
        });
        return newDict;
      });
      return sortByDateTime(rowsWithId, "desc");
    }, [
      authorizationsRefusedList,
      findKeysToTranslate,
      keysToTranslate,
      transactionsList,
    ]);

  useEffect(() => {
    if (company) {
      void dispatch(transactionsListAsync(company.uuid));
      void dispatch(authorizationsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (
      sendSmsStatus === "success" ||
      sendSmsReplaceInvalidStatus === "success"
    ) {
      triggerToast(t(`transactions.sendSms.success`) || "", "valid");
    } else if (
      sendSmsStatus === "failed" ||
      sendSmsReplaceInvalidStatus === "failed"
    ) {
      triggerToast(t("transactions.sendSms.failure") || "", "error");
    }
  }, [sendSmsReplaceInvalidStatus, sendSmsStatus, t]);

  useEffect(() => {
    if (
      allTransactionsDisplayed &&
      transactionsListStatus === "success" &&
      authorizationsListStatus === "success"
    ) {
      setListDisplayed(allTransactionsDisplayed);
    }
  }, [
    allTransactionsDisplayed,
    authorizationsListStatus,
    transactionsListStatus,
  ]);

  const sidePanelAction = useCallback(
    (props: Partial<PublicTransactionsPropsType>) => {
      navigate(`/home/transactions/cards/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      const transactionSelected = allTransactionsDisplayed.find(
        (t) => t.uuid === params.id,
      );
      if (transactionSelected) {
        navigate(`/home/transactions/cards/${transactionSelected.uuid}`);
      }
    }
  }, [allTransactionsDisplayed, navigate, params.id]);

  const searchBarProps = {
    values: allTransactionsDisplayed,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "uuid" as const,
      "merchantCity" as const,
      "cardLast4Digits" as const,
      "reasonsRefused" as const,
      "mileage" as const,
      "quantity" as const,
      "consumption" as const,
      "supportingDocumentUuid" as const,
      "driverPhone" as const,
      "driver" as const,
      "driverLastName" as const,
      "ruleName" as const,
      "ruleUuid" as const,
      "vehicleUuid" as const,
      "cardUuid" as const,
      "fuel" as const,
      "carbonEmission" as const,
      "areSupportingDocumentsMandatory" as const,
    ],
  };

  const columnsDisplayed: {
    [index in keyof PublicTransactionDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      type: {
        type: "string",
        width: 60,
        valueFormatter: () => {
          return (
            <StyledRow>
              <LittleCardAsset />
            </StyledRow>
          );
        },
      },
      date: {
        type: "date",
        width: 210,
        valueGetter: getDateTime,
        valueFormatter: formatDateTime,
      },
      merchantName: {
        type: "string",
        width: 250,
      },
      category: {
        type: "string",
        valueFormatter: (value, row) => {
          const merchantCategory = getCategoryName(value);
          return (
            value && (
              <StyledRow>
                <Tag
                  text={merchantCategory}
                  backgroundColor={
                    colors["colors/surfaces/background/background_level2"]
                  }
                  textColor={colors["colors/text/black"]}
                  colorHover={
                    rowHoverUuid === row["uuid"]
                      ? colors["colors/surfaces/background/background_level0"]
                      : undefined
                  }
                />
              </StyledRow>
            )
          );
        },
      },
      driverName: {
        type: "string",
        width: 250,
        valueFormatter: (value: string, row) => {
          const initials = value?.split(" ").map((row) => row.charAt(0));
          return (
            value && (
              <StyledRow>
                <Avatar
                  color={"mint"}
                  size={"S"}
                  firstName={initials[0]}
                  lastName={initials[initials.length - 1]}
                  isHover={rowHoverUuid === row["uuid"]}
                />
                <Spacer x={0.75} />
                {value}
              </StyledRow>
            )
          );
        },
      },
      status: {
        type: "string",
        valueFormatter: (value: TransactionStatus, row) =>
          value && (
            <StyledRow>
              <TableTagTransaction
                status={value}
                isRowHover={rowHoverUuid === row["uuid"]}
              />
            </StyledRow>
          ),
      },
      supportingDocumentStatus: {
        type: "string",
        valueFormatter: (value: string, row) => {
          return (
            value && (
              <StyledRow>
                <Tag
                  text={value}
                  backgroundColor={
                    value === t("transactions.supportingDocument.ACCEPTED")
                      ? colors["colors/system/success/success_ultraLight"]
                      : colors["colors/surfaces/background/background_level2"]
                  }
                  textColor={
                    value === t("transactions.supportingDocument.ACCEPTED")
                      ? colors["colors/system/success/success_normal"]
                      : colors["colors/text/black"]
                  }
                  colorHover={
                    value !== t("transactions.supportingDocument.ACCEPTED") &&
                    rowHoverUuid === row["uuid"]
                      ? colors["colors/surfaces/background/background_level0"]
                      : undefined
                  }
                />
              </StyledRow>
            )
          );
        },
      },
      amount: {
        type: "number",
        valueFormatter: (value: number, row) =>
          value && (
            <StyledAmount
              $isCanceled={row.status === t("transactions.status.CANCELED")}
            >
              {formatCurrency(value)}
            </StyledAmount>
          ),
      },
      mileage: {
        type: "number",
        valueFormatter: formatMileage,
      },
    };
  }, [rowHoverUuid, t]);

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("transactions.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "cards") {
    return;
  }

  return (
    <>
      <MaterialUiTable<
        keyof PublicTransactionDisplayed,
        PublicTransactionDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        sidePanelAction={sidePanelAction}
        setRowHoverUuid={setRowHoverUuid}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
        downloadViewAction={downloadViewAction}
      />
      <TransactionsSidePanel />
    </>
  );
};

const StyledAmount = styled.div<{
  $isCanceled?: boolean;
}>`
  text-decoration: ${({ $isCanceled }) =>
    $isCanceled ? "line-through" : "none"};
  ${typographies["Body/L"]}
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
