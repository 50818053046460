import { useTranslation } from "react-i18next";
import { PublicCompany, selectCompany } from "../homeSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Avatar,
  ButtonDS,
  Chips,
  DownloadOutlined,
  EditOutlined,
  EmptyTableComponent,
  IconCircle,
  InputDS,
  LaunchPage,
  PendingActionsOutlined,
  SortByNumericType,
  SortingDirectionType,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterLongMonthYear,
  hasEmailFormat,
  sortByDateTime,
  sortByNumeric,
  triggerToast,
} from "@qivia/ui";
import {
  PublicStatementsDisplayed,
  PublicStatements,
  UpdateCompanyType,
  PublicStatementsDisplayedRow,
} from "./billingsAPI";
import {
  billingsSlice,
  selectStatementExportLink,
  selectStatementExportStatus,
  selectStatementsList,
  selectStatementsListStatus,
  documentDownloadAsync,
  statementExportAsync,
  statementsListAsync,
  supportingDocumentsZipFolderExportAsync,
  updateRecipientEmailAsync,
  deleteRecipientEmailAsync,
} from "./billingsSlice";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useParams } from "react-router-dom";

const EmailDisplay = (props: {
  onClick: () => void;
  company: PublicCompany;
  isInputEmailOpen: boolean;
  setIsEmailInputOpen: (val: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const [recipientEmails, setRecipientEmails] = useState<string[]>([]);

  useEffect(() => {
    if (props.company) setRecipientEmails(props.company.recipientsEmails);
  }, [props.company]);

  const clickToDelete = useCallback(
    (email: string) => {
      const payload: UpdateCompanyType = {
        uuid: props.company.uuid,
        recipientsEmails: recipientEmails.filter(
          (recipientEmail) => recipientEmail !== email,
        ),
      };
      if (payload.recipientsEmails.length !== 0)
        void dispatch(deleteRecipientEmailAsync(payload));
    },
    [dispatch, recipientEmails, props.company.uuid],
  );

  return (
    <StyledEmailDisplay>
      {recipientEmails.map((recipientEmail) => {
        return (
          <>
            <Chips
              size={"L"}
              text={recipientEmail}
              leftElement={
                <Avatar color={"green"} firstName={recipientEmail} size={"S"} />
              }
              onCrossClick={() => clickToDelete(recipientEmail)}
            />
            <Spacer x={0.5} />
          </>
        );
      })}
      {props.isInputEmailOpen ? (
        <EmailInput
          company={props.company}
          onClick={() => props.setIsEmailInputOpen(false)}
        />
      ) : (
        <ButtonDS
          buttonType={"secondary"}
          format={"hug"}
          singleIcon={{ icon: <EditOutlined />, size: "S" }}
          onClick={props.onClick}
        />
      )}
    </StyledEmailDisplay>
  );
};

const EmailInput = (props: { onClick: () => void; company: PublicCompany }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [recipientEmails, setRecipientEmails] = useState<string[]>([]);
  const [recipientEmailInput, setRecipientEmailInput] = useState<string>("");

  useEffect(() => {
    if (props.company) setRecipientEmails(props.company.recipientsEmails);
  }, [props.company]);

  const isValidForm: boolean = useMemo(
    () =>
      hasEmailFormat(recipientEmailInput) &&
      recipientEmailInput !== "" &&
      !recipientEmails.includes(recipientEmailInput),
    [recipientEmails, recipientEmailInput],
  );

  const onSubmit = useCallback(
    (emails: string[]) => {
      const payload: UpdateCompanyType = {
        uuid: props.company.uuid,
        recipientsEmails: emails,
      };
      void dispatch(updateRecipientEmailAsync(payload));
      props.onClick();
    },
    [dispatch, props],
  );

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidForm && onSubmit([...recipientEmails, recipientEmailInput]);
  }, [isValidForm, recipientEmails, onSubmit, recipientEmailInput]);

  const getEmailError = useCallback(() => {
    if (!displayError) return undefined;
    if (recipientEmailInput === "") {
      return t("billings.emailsFrame.placeholder.error.empty");
    } else if (!hasEmailFormat(recipientEmailInput)) {
      return t("billings.emailsFrame.placeholder.error.format");
    } else if (recipientEmails.includes(recipientEmailInput)) {
      return t("billings.emailsFrame.placeholder.error.duplicate");
    } else return undefined;
  }, [displayError, recipientEmails, recipientEmailInput, t]);

  return (
    <StyledEmailInput>
      <InputDS
        placeholder={t("billings.emailsFrame.placeholder")}
        error={getEmailError()}
        value={recipientEmailInput}
        onChange={(e) => setRecipientEmailInput(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        withoutSpacerBottom
        height={2.18}
      />
      <Spacer x={0.5} />
      <StyledButton>
        <ButtonDS
          sizeButton={"S"}
          text={t("billings.emailsFrame.save")}
          format={"hug"}
          buttonType={"secondary"}
          onClick={clickToSubmit}
          disabled={!isValidForm}
        />
      </StyledButton>
    </StyledEmailInput>
  );
};

export const EmailsFrame = (props: { company: PublicCompany }) => {
  const { t } = useTranslation();
  const [isInputEmailOpen, setIsEmailInputOpen] = useState<boolean>(false);

  return (
    <StyledEmailsFrameContainer>
      <Spacer y={1} />
      <StyledEmailContent>
        <Spacer x={1.5} />
        <StyledColumn>
          <Spacer y={1.5} />
          <StyledFlex>
            <IconCircle
              icon={<PendingActionsOutlined />}
              backgroundColor={"accent500"}
              iconColor={"textWhite"}
            />
            <Spacer x={0.75} />
            <StyledHeader>
              <StyledTitle>
                <TextCapitalized>
                  {t("billings.emailsFrame.title")}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={0.25} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t(`billings.emailsFrame.display.subTitle`)}
                </TextCapitalized>
              </StyledSubTitle>
            </StyledHeader>
          </StyledFlex>
          <Spacer y={1.5} />
          <StyledBody>
            <EmailDisplay
              onClick={() => setIsEmailInputOpen(true)}
              company={props.company}
              isInputEmailOpen={isInputEmailOpen}
              setIsEmailInputOpen={setIsEmailInputOpen}
            />
          </StyledBody>
          <Spacer y={1.5} />
        </StyledColumn>
        <Spacer x={1.5} />
      </StyledEmailContent>
    </StyledEmailsFrameContainer>
  );
};

export const StatementTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");

  const [billingsListSorted, setBillingsListSorted] = useState<
    PublicStatementsDisplayed[] | null
  >([]);
  const statementsListStatus = useAppSelector(selectStatementsListStatus);
  const statementsExportStatus = useAppSelector(selectStatementExportStatus);
  const statementsListInit: PublicStatements[] =
    useAppSelector(selectStatementsList);
  const statementExportLink = useAppSelector(selectStatementExportLink);
  const supportingDocumentsZipFolderExportAsyncStatus = useAppSelector(
    selectStatementExportStatus,
  );
  const statementsList: PublicStatements[] = useMemo(() => {
    return sortByDateTime(statementsListInit, "desc");
  }, [statementsListInit]);

  useEffect(() => {
    if (company) {
      void dispatch(statementsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (statementExportLink.presignedUrl && statementExportLink.fileName) {
      void dispatch(documentDownloadAsync(statementExportLink));
    }
  }, [statementExportLink, dispatch]);

  useEffect(() => {
    if (statementsExportStatus === "success") {
      triggerToast(t("billings.statements.upload.success") || "", "valid");
      dispatch(billingsSlice.actions.resetStatementsExportStatus());
    } else if (statementsExportStatus === "failed") {
      triggerToast(t("billings.statements.upload.failure") || "", "error");
      dispatch(billingsSlice.actions.resetStatementsExportStatus());
    }
  }, [dispatch, statementsExportStatus, t]);

  useEffect(() => {
    if (supportingDocumentsZipFolderExportAsyncStatus === "failed") {
      triggerToast(t("billings.zipfolder.export.error") || "", "error");
      dispatch(billingsSlice.actions.resetStatementsExportStatus());
    }
  }, [dispatch, supportingDocumentsZipFolderExportAsyncStatus, t]);

  useEffect(() => {
    if (statementsList && statementsListStatus === "success") {
      const billingsDateTimeSorted = sortByDateTime(statementsList, "desc");
      setBillingsListSorted(billingsDateTimeSorted);
    }
  }, [statementsList, statementsListStatus]);

  const sortByStatementsDate = useCallback(() => {
    if (!billingsListSorted) return;
    const billingsDateTimeSorted = sortByDateTime(
      billingsListSorted,
      sortingDirection,
    );
    setBillingsListSorted(billingsDateTimeSorted);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  }, [billingsListSorted, sortingDirection]);

  const sortStatementsByAmount = useCallback(
    (column: string) => {
      if (!billingsListSorted) return;

      const transactionsAmountSorted = sortByNumeric(
        billingsListSorted,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setBillingsListSorted(transactionsAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [billingsListSorted, sortingDirection],
  );

  const headers = {
    date: {
      text: t("billings.table.column.date"),
      sortAction: () => sortByStatementsDate(),
    },
    amount: {
      text: t("billings.table.column.amount"),
      sortAction: () => sortStatementsByAmount("amount"),
    },
    bucketFileName: {
      text: t("billings.table.column.downloadStatement"),
    },
    invoiceBucketFileName: {
      text: t("billings.table.column.downloadStatementInvoice"),
    },
    zipFolder: {
      text: t("billings.table.column.downloadZipFolder"),
    },
  };

  if (params.tab !== "statements") {
    return;
  }

  if (!billingsListSorted) return <LaunchPage hasBorderRadius={true} />;

  const render =
    (row: PublicStatementsDisplayedRow) =>
    (key: keyof PublicStatementsDisplayed) => {
      switch (key) {
        case "date":
          return (
            <TextCapitalized>
              {dateFormatterLongMonthYear(new Date(row[key]))}
            </TextCapitalized>
          );
        case "amount":
          return (
            <StyledAmount>
              {Number(row[key] / 100).toString() + " EUR"}
              <Spacer x={0.75} />
            </StyledAmount>
          );
        case "bucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    statementExportAsync({
                      bucketFileName: row[key] ?? "",
                      fileName: row["fileName"] ?? "",
                    }),
                  );
                }}
              />
            )
          );
        case "invoiceBucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    statementExportAsync({
                      bucketFileName: row[key] ?? "",
                      fileName: `${row["fileName"] ? row["fileName"].split(".xlsx")[0] + ".pdf" : ""}`,
                    }),
                  );
                }}
              />
            )
          );
        case "zipFolder":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    supportingDocumentsZipFolderExportAsync({
                      zipFolderUuid: row[key],
                      date: row["date"],
                      companyName: company?.name ?? "",
                    }),
                  );
                }}
              />
            )
          );
      }
    };

  return (
    <TableDS<keyof PublicStatementsDisplayed, PublicStatementsDisplayed>
      data={billingsListSorted}
      headers={headers}
      render={render}
      emptyContent={<EmptyTableComponent pageName={"billings"} />}
    />
  );
};

const StyledEmailDisplay = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledBody = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledButton = styled.div`
  width: 7rem;
`;

const StyledEmailsFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Header/H2"]}
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const StyledEmailContent = styled.div`
  display: flex;
  border-radius: 0.5rem;
  background: ${colors["colors/surfaces/background/background_level1"]};
  width: 100%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTitle = styled.div`
  ${typographies["Body/XL"]};
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/XS"]};
`;

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledEmailInput = styled.div`
  display: flex;
  align-items: center;
  min-width: 30rem;
`;
